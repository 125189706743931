export class Restaurante {
  id: number;
  idOrg: string;
  nombre:string;
  descripcion:string;
  urlImagen:string;
  created:Date;
  activo:boolean;
  idIdioma:number;
  listaTraducciones:RestauranteTrad[];
  updateFoto:boolean;
}
export class RestauranteTrad {
  id: number;
  idRestaurante:number;
  idIdioma:number;
  nombre:string;
  descripcion:string;
  filled:boolean;
  nombreIdioma:string;
}
export class ComidaTipo {
    id: number;
    idRestaurante: string;
    nombre:string;
    horaInicio:Date;
    horaFin:Date;
    horasReserva:string;
    listaTraducciones:ComidaTipoTrad[];
    idIdioma:number;
    activo:boolean;
  }

  export class ComidaTipoTrad {
    id: number;
    idRestaurante:number;
    idIdioma:number;
    idComida:number;
    nombre:string;
    filled:boolean;
    nombreIdioma:string;
  }

  export class Comedor {
    id: number;
    idRestaurante: number;
    nombre:string;
    urlFondo:string;
    created:Date;
    alto:number;
    ancho:number;
    expandido:boolean;
    idIdioma:number;
    listaTraducciones:ComedorTrad[];
    updateFoto:boolean;
    listaMesaComedor:MesaComedor[];
  }
  export class ComedorTrad {
    id: number;
    idComedor:number;
    idIdioma:number;
    nombre:string;
    filled:boolean;
    nombreIdioma:string;
  }

  export class Mesa {
    id: number;
    idRestaurante: number;
    nombre:string;
    urlFoto:string;
    created:Date;
    activo:boolean;
    comensales:number;
    isMesa:boolean;
    idIdioma:number;
    listaTraducciones:MesaTrad[];
    updateFoto:boolean;
    zIndex:number;
    posX:number;
    posY:number;
    altura:number;
    anchura:number;
    numero:number;
  }
  export class MesaTrad {
    id: number;
    idMesa:number;
    idIdioma:number;
    nombre:string;
    filled:boolean;
    nombreIdioma:string;
  }

  export class MesaComedor {
    id: number;
    idComedor: number;
    numeromesa:number;
    urlFoto:string;
    posX:number;
    posY:number;
    rotacion:number;
  }
  export class ReservaRestaurante{
    id:number;
    idUser:number;
    idRestaurante:number;
    idComedor:number;
    idMesa:number;
    idTipoComida:number;
    numComensales:number;
    estado:number;
    fecha:Date;
    created:Date;
    observaciones:string;
  }

  export class ReservaPeticion{
    idComedor:number;
    fechaIni:Date;
    fechaFin:Date;
  }
  