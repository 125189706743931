import { DecimalPipe } from '@angular/common';
import { Component, HostListener, ViewChild,OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormsModule } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Usuario } from '@app/_models';
import { AlertService, MenuService, UsuariosService } from '@app/_services';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { FileRestrictions, RemoveEvent, SelectEvent } from '@progress/kendo-angular-upload';
import { expand, first } from 'rxjs/operators';
import * as $ from 'jquery';
import 'jquery-ui/ui/widgets/draggable.js';
import 'jquery-ui/ui/widgets/resizable.js';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { RestauranteService } from '@app/_services/restaurante.service';
import { Comedor, ComedorTrad, Mesa, MesaComedor, MesaTrad } from '@app/_models/restaurante';
import { Idioma } from '@app/_models/idioma';
import { RecintoTrad } from '@app/_models/recinto';
import { IdiomasService } from '@app/_services/idiomas.service';

@Component({
  selector: 'app-comedores-detalle',
  templateUrl: './comedores-detalle.component.html',
})
export class ComedoresDetalleComponent implements OnInit {
  user = this.usuariosService.userValue;
  public submitted = false;
  private translate: TranslateService;
  public id: any;
  navigationSubscription;
  isAddMode: boolean;
  largo: 1000;
  ancho: 1000;
  mesas: any = [];
  objetos: any = [];
  imagenes: any = [];
  comensales: any;
  observaciones: any;
  fondo: any;
  imagenesMesasObjetos: any = [];
  seleccionados: any = [];
  zIndex = "1";
  codFondo;
  expandido;
  errorNombreVacio = false; 

  //Modals
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;
  @ViewChild('popupAgregarMesa') popupAgregarMesa: NgbModalRef;
  @ViewChild('popupNuevaEditarMesa') popupNuevaEditarMesa: NgbModalRef;
  @ViewChild('popupBorrarMesa') popupBorrarMesa: NgbModalRef;
  @ViewChild('popupAgregarObjeto') popupAgregarObjeto: NgbModalRef;
  @ViewChild('popupNuevoEditarObjeto') popupNuevoEditarObjeto: NgbModalRef;
  @ViewChild('popupBorrarObjeto') popupBorrarObjeto: NgbModalRef;
  @ViewChild('popupAgregarFondo') popupAgregarFondo: NgbModalRef;
  @ViewChild('popupNuevoEditarFondo') popupNuevoEditarFondo: NgbModalRef;
  @ViewChild('popupBorrarFondo') popupBorrarFondo: NgbModalRef;
  @ViewChild('popupAbrirCalendario') popupAbrirCalendario: NgbModalRef;
  modalReference: NgbModalRef;
  modalReference2: NgbModalRef;
  modalReference3: NgbModalRef;

  //MESAS MODAL
  mesasModal: any;
  mesaSeleccionadaModal: any;
  nombre1MesaModalError= true;
  submittedModalMesas: any;
  nombre1MesaModal: any;
  nombre2MesaModal: any;
  nombre2MesaModalError= true;
  numComensalesMesasModal: any;
  errorComensalesModalMesas: any;
  errorImagenModalMesas: any;
  imageToShowModalMesas: any;
  archivoModalMesas: any;
  isAddModeModalMesas: any;
  codigoModalMesas: any;

  //OBJETOS MODAL
  objetosModal: any;
  codigoModalObjetos: any;
  objetoSeleccionadoModal: any;
  nombre1ObjetoModalError= true;
  submittedModalObjeto: any;
  nombre1ObjetoModal: any;
  nombre2ObjetoModal: any;
  nombre2ObjetoModalError: any;
  imageToShowModalObjeto: any;
  archivoModalObjeto: any;
  errorImagenModalObjeto: any;
  isAddModeModalObjeto: any;

  //FONDOS MODAL
  fondosModal: any;
  codigoModalFondos: any;
  fondoSeleccionadoModal: any;
  submittedModalFondo: any;
  imageToShowModalFondo: any;
  archivoModalFondo: any;
  errorImagenModalFondo: any;
  isAddModeModalFondo: any;
  verExpandidoModalFondo: any;
  verExpandidoExteriorModalFondo: any;

  calendarioActivado = false;

  public datosCargados = false;
  public restrictionsImage: FileRestrictions = {
    allowedExtensions: ['.jpg', '.jpeg', '.png', '.gif'],
    maxFileSize: 1048576
  };

    //Data
    listaMesas:Mesa[]= [];
    isBusy:boolean=false;
    listaIdiomas: Idioma[] = [];
    listaIdiomasTotales: Idioma[] = [];
    listaTraducciones: Idioma[] = [];
    selectedIdioma: Idioma;
    listaMesasComedor:MesaComedor[]=[];
    //Traducciones
    listaTraduccionesEnviar: ComedorTrad[] = [];
    //Params
    idOrg:string;
    idRestaurante:number;
    idComedor:number;
    nombre:string='';


  //Variables calendario
  public listItemsComedor: any;
    public comedorSelected: any;
    public comedorSelectedModal: any;
    public listItemsComida: any;
    public comidaSelected: any;
    public comidaSelectedModal: any;
    public lunes=false;
    public martes=false;
    public miercoles=false;
    public jueves=false;
    public viernes=false;
    public sabado=false;
    public domingo=false;
    public diasActivos;
    public diasInactivos;
    public year;
    public periodoInactivoClickado = false;
    public datosCargadosCalendario = false;
    @ViewChild('popupCopiar') popupCopiar: NgbModalRef;

  constructor(
    private restauranteService: RestauranteService, private idiomaService:IdiomasService,
    translate: TranslateService,
    private menuService: MenuService,
    private route: ActivatedRoute,
    public router: Router,
    private translateService: TranslateService,
    private usuariosService: UsuariosService, private modalService: NgbModal,
    private formBuilder: FormBuilder, private alertService: AlertService, private sanitizer: DomSanitizer) {
    this.translate = translateService;
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        if (this.router.url.startsWith('/comedores/editar') == true) {
          this.menuService.titulo = this.translate.instant('comedor');
          this.id = Number(this.route.snapshot.params['id']);
          if (this.id == -1) {
            this.isAddMode = true;
          } else {
            this.isAddMode = false;
          }
          
        }
      }
    });
  }


  ngOnInit(): void {
    this.idOrg = this.route.snapshot.params['idorg'];
    this.idRestaurante = this.route.snapshot.params['idrestaurante'];
    this.idComedor = this.route.snapshot.params['idcomedor'];
      this.isAddMode=this.idComedor==-1;

    this.nombre = "";
    this.largo = 1000;
    this.ancho = 1000;
    this.comensales = 0;
    this.observaciones = "";
    this.cargarDatos();
    this.loadData();//asier

    //Load Langs
    this.idiomaService.GetAll().subscribe((result) => {
      this.listaIdiomasTotales = result.data;
      console.log('Cargados idiomas = ' + this.listaIdiomasTotales.length);
      this.listaIdiomasTotales.forEach((x) => {
        const trad = new ComedorTrad();
        trad.filled = false;
        trad.idIdioma = x.codigo;
        trad.nombreIdioma = x.nombre;
        this.listaTraduccionesEnviar.push(trad);
      });
    });

    this.idiomaService.getIdiomasFromEnte(this.idOrg).subscribe((result) => {
      this.listaIdiomas = result.data;
      if (this.listaIdiomas.length > 0)
        this.selectedIdioma = this.listaIdiomas[0];
    });
  }
  
  cargarDatos() {
    if (!this.isAddMode) { //editando
      console.log('Editando comedor');
      this.restauranteService.getComedoresByIdLang(this.idComedor,1).subscribe((result: any)=>{
        console.log('LLEGA:');
        console.log(result);
        if(result.data.length>0){
          this.nombre = result.data[0].nombre;
          this.largo = result.data[0].alto;
          this.ancho = result.data[0].ancho;
          this.comensales = result.data[0].comensales;
          this.observaciones = result.data[0].observaciones;
          if(result.data[0] != ""){
            this.fondo = result.data[0].fondo;
            this.codFondo = result.data[0].codfondo;
            this.expandido = result.data[0].expandido;
            if(!this.expandido){
              this.imagenes = [];
              for (var i = 0; i < 20; i++) {
                this.imagenes.push(this.fondo);
              }
            }else{
              this.imagenes = [this.fondo];
            }
            
          }
          if(result.data.length>0){
            this.mesas = [];
            this.objetos = [];
            var zIndex = 0;
            //Ahora revisamos las mesas que hay asignadas a ese comedor
            var element;
            var contarElementosConDatos = 0;
            for(var i = 0; i< result.mesas.length; i++){
              element = result.mesas[i];
              element.idAuxiliar = result.mesas[i].id; //necesitamos utilizar un código auxiliar porque se seleccionan por código y los nuevos no tiene
              element.zIndex = zIndex.toString(); //les damos un z index según los vamos colocando
              zIndex++;
              var ObjectURL = element.urlFoto;
              var img;
              if(element.rotar == 0 || element.rotar == null){
                element.imagen = ObjectURL;
                this.imagenesMesasObjetos.push(element);
                if(element.numero == 0){ //es un objeto
                  this.objetos.push(element);
                }else if(element.numero >0){ //es una mesa
                  this.mesas.push(element);
                }
                contarElementosConDatos++;
                if(contarElementosConDatos==result.mesas.length){
                  this.hacerDraggables(this);
                  this.datosCargados = true;
                }
              }
              else if(element.rotar == 1){
                img = new Image();
                //le asignamos el enlace a la imagen auxiliar
                img.src = ObjectURL;
                // Creamos un canvas para rotarla
                let canvas = document.createElement("canvas");
                // Hay que esperar a que la imagen se cargue
                var that = this;
                img.element = element; //la función es asíncrona, necesitamos asignarselo para que tenga ese elemento al entrar en la función
                img.onload = function(){

                    //Cogemos el contexto del canvas
                    let ctx = canvas.getContext("2d");
                    // Le asignamos el tamaño
                    canvas.width = img.height;
                    canvas.height = img.width;
                    //Ponemos el contexto en el centro del canvas
                    ctx.translate(canvas.width / 2,canvas.height / 2);

                    // Rotamos la imagen
                    ctx.rotate(90*(Math.PI/180));
                    ctx.drawImage(img, -img.width / 2, -img.height / 2, img.width, img.height); //y la pintamos en el canvas
                    var imagenTransformada = that.convertCanvasToImage(canvas);
                    img.element.imagen = imagenTransformada.src;
                    img.element.ancho = img.element.alto;
                    img.element.alto = img.element.ancho;
                    that.imagenesMesasObjetos.push(img.element);
                    if(img.element.numero == 0){ //es un objeto
                      that.objetos.push(img.element);
                    }else if(img.element.numero >0){ //es una mesa
                      that.mesas.push(img.element);
                    }
                    contarElementosConDatos++;
                    if(contarElementosConDatos==result.mesas.length){
                      that.hacerDraggables(that);
                      that.datosCargados = true;
                    }
                }
              }
              else if(element.rotar == 2){
                img = new Image();
                //le asignamos el enlace a la imagen auxiliar
                img.src = ObjectURL;
                // Creamos un canvas para rotarla
                let canvas = document.createElement("canvas");
                // Hay que esperar a que la imagen se cargue
                var that = this;
                img.element = element; //la función es asíncrona, necesitamos asignarselo para que tenga ese elemento al entrar en la función
                img.onload = function(){

                    //Cogemos el contexto del canvas
                    let ctx = canvas.getContext("2d");
                    // Le asignamos el tamaño
                    canvas.width = img.width;
                    canvas.height = img.height;
                    //Ponemos el contexto en el centro del canvas
                    ctx.translate(canvas.width / 2,canvas.height / 2);

                    // Rotamos la imagen
                    ctx.rotate(180*(Math.PI/180));
                    ctx.drawImage(img, -img.width / 2, -img.height / 2, img.width, img.height); //y la pintamos en el canvas
                    var imagenTransformada = that.convertCanvasToImage(canvas);
                    img.element.imagen = imagenTransformada.src;
                    img.element.ancho = img.element.ancho;
                    img.element.alto = img.element.alto;
                    that.imagenesMesasObjetos.push(img.element);
                    if(img.element.numero == 0){ //es un objeto
                      that.objetos.push(img.element);
                    }else if(img.element.numero >0){ //es una mesa
                      that.mesas.push(img.element);
                    }
                    contarElementosConDatos++;
                    if(contarElementosConDatos==result.mesas.length){
                      that.hacerDraggables(that);
                      that.datosCargados = true;
                    }
                }
              }
              else if(element.rotar == 3){
                img = new Image();
                //le asignamos el enlace a la imagen auxiliar
                img.src = ObjectURL;
                // Creamos un canvas para rotarla
                let canvas = document.createElement("canvas");
                // Hay que esperar a que la imagen se cargue
                var that = this;
                img.element = element; //la función es asíncrona, necesitamos asignarselo para que tenga ese elemento al entrar en la función
                img.onload = function(){

                    //Cogemos el contexto del canvas
                    let ctx = canvas.getContext("2d");
                    // Le asignamos el tamaño
                    canvas.width = img.height;
                    canvas.height = img.width;
                    //Ponemos el contexto en el centro del canvas
                    ctx.translate(canvas.width / 2,canvas.height / 2);

                    // Rotamos la imagen
                    ctx.rotate(270*(Math.PI/180));
                    ctx.drawImage(img, -img.width / 2, -img.height / 2, img.width, img.height); //y la pintamos en el canvas
                    var imagenTransformada = that.convertCanvasToImage(canvas);
                    img.element.imagen = imagenTransformada.src;
                    img.element.ancho = img.element.alto;
                    img.element.alto = img.element.ancho;
                    that.imagenesMesasObjetos.push(img.element);
                    if(img.element.numero == 0){ //es un objeto
                      that.objetos.push(img.element);
                    }else if(img.element.numero >0){ //es una mesa
                      that.mesas.push(img.element);
                    }
                    contarElementosConDatos++;
                    if(contarElementosConDatos==result.mesas.length){
                      that.hacerDraggables(that);
                      that.datosCargados = true;
                    }
                }
              }           
            }
            
          }
          else{
            this.datosCargados = true;            
          }
        }
      });
    }else{
      //si es nuevo inicializamos los datos
      console.log('Nuevo Comedor');
      this.nombre = "";
      this.largo = 1000;
      this.ancho = 1000;
      this.comensales = 0;
      this.observaciones = "";
      this.mesas = [];
      this.objetos = [];
      this.datosCargados= true;
    } 
  }
   
  loadData(){
    this.restauranteService.getMesas(0,true).subscribe((result)=>{
      console.log(result);
      this.listaMesas = result.data;
    });
  }
  hacerDraggables(thisActual){
     //Ahora que hemos rellenado el array de mesas y objetos, las hacemos draggable
     //limpiamos el canvas, hay que dejarle solo el fondo para poder añadir a los objetos
     //guardamos la selección previa para que no desaparezca
     var elementoSeleccionado = document.getElementsByClassName("comedores-imagenSeleccionada");
     var idAuxiliar = -1;
     if(elementoSeleccionado.length==1)
        idAuxiliar=Number(elementoSeleccionado[0].id);
     var fondo = document.getElementById("fondo");
     document.getElementById("canvas").innerHTML =""; //Lo vaciamos
     document.getElementById("canvas").append(fondo);
     var canvas = $("#canvas");
     var that = thisActual;
     var auxiArray= []; //si estamos introduciendo el primero objeto no podremos filtrar porque estará vacía
     if(thisActual.imagenesMesasObjetos.length>0)
      auxiArray = thisActual.imagenesMesasObjetos.filter(x=>x.eliminado!=true);
     for(var i = 0; i< auxiArray.length; i++){
       var element;
       console.log(auxiArray[i]);
       var clase = auxiArray[i].idAuxiliar == idAuxiliar? "comedores-imagenSeleccionada": "";
       if(auxiArray[i].numero>0)
         element = `<div class="ContenedoresImagenes" id="obj${auxiArray[i].idAuxiliar}" data-codigo="${auxiArray[i].codigo}" tabindex="0" class="draggable" style="center;position: absolute;width:${auxiArray[i].ancho}px;height: ${auxiArray[i].alto}px;top:${auxiArray[i].y}px;left:${auxiArray[i].x}px;z-index:${Number(auxiArray[i].zIndex)}">
         <div class="rotateable" id="rotateable${i}">
         <img class="${clase}" id="${auxiArray[i].idAuxiliar}" style="object-fit: fill;width: 100%;height: 100%;" type="image" src="${auxiArray[i].imagen}">
         <span class="comedores-numeroMesa">${auxiArray[i].numero}</span>
         </div>
        </div>`;
      else
        element = `<div class="ContenedoresImagenes" id="obj${auxiArray[i].idAuxiliar}" data-codigo="${auxiArray[i].codigo}" tabindex="0" class="draggable" style="center;position: absolute;width:${auxiArray[i].ancho}px;height: ${auxiArray[i].alto}px;top:${auxiArray[i].y}px;left:${auxiArray[i].x}px;z-index:${Number(auxiArray[i].zIndex)}">
        <div class="rotateable" id="rotateable${i}">
        <img class="${clase}" id="${auxiArray[i].idAuxiliar}" style="object-fit: fill;width: 100%;height: 100%;" type="image" src="${auxiArray[i].imagen}">
        </div>
      </div>`;
        
       //Get children y con eso coger la imagen para cambiarle el src
       canvas.append(element);
       var elementoHTML = document.getElementById("obj" + auxiArray[i].idAuxiliar.toString()).children[0].children[0]; //Tenemos la imagen
       var that = thisActual;
       elementoHTML.addEventListener("click", function(event){ that.onClickImage(event.target); });
       
       
       ($("#obj" + auxiArray[i].idAuxiliar) as any).draggable({
         cursor: "move",
         disabled: false,
         start: thisActual.startFix,
         drag: thisActual.dragFix.bind(thisActual)
       });

       ($("#obj" + auxiArray[i].idAuxiliar) as any).resizable({
         containment: "#canvas",
         disabled: false,
         minWidth: auxiArray[i].ancho * 0.25,
         minHeight: auxiArray[i].alto * 0.25,
         resize: thisActual.resize.bind(thisActual)
       });
     }
  }

  //función al comienzo del drag
  startFix(event, ui) {
    //seleccionamos el elemento que tenga activo el movimiento
    if(document.getElementsByClassName("comedores-imagenSeleccionada").length == 1) //Solo debería haber uno
    {
      document.getElementsByClassName("comedores-imagenSeleccionada")[0].classList.remove("comedores-imagenSeleccionada");
    }
    event.target.classList.add("comedores-imagenSeleccionada");
    this.seleccionados = [];
    ui.position.left = 0;
    ui.position.top = 0;
  }

  

  // funcion para mover elemento
  dragFix(event, ui) {
    //Le cambiamos el zIndex para que se posicione on top
    var elemento = this.imagenesMesasObjetos.filter(x=>x.idAuxiliar == Number(event.target.id.split("obj")[1]))[0];
    var AuxZindex;
    if(elemento.zIndex != undefined){
      if(this.buscarElementoZIndex(elemento.zIndex.toString()) !== null){
        AuxZindex = (Number(this.buscarElementoZIndex(elemento.zIndex.toString())) + 1).toString();
      }else{
        AuxZindex = elemento.zIndex;
      }
    }else{
      AuxZindex= "0";
    }
    
    //Ahora calculamos la nueva x y nueva y
    var changeLeft = ui.position.left - ui.originalPosition.left,
      newLeft = ui.originalPosition.left + changeLeft,
      changeTop = ui.position.top - ui.originalPosition.top,
      newTop = ui.originalPosition.top + changeTop;

    if (newLeft >= $("#canvas").width() - $(ui.helper[0]).width()) {
      newLeft = $("#canvas").width() - $(ui.helper[0]).width();
    }
    if (newLeft <= 0) {
      newLeft = 0;
    }
    if (newTop <= 0) {
      newTop = 0;
    }
    if (newTop >= $("#canvas").height() - $(ui.helper[0]).height()) {
      newTop = $("#canvas").height() - $(ui.helper[0]).height();
    }

    //Y las actualizamos
    ui.position.left = newLeft;
    ui.position.top = newTop;

    //Ahora obtenemos el objeto en el array y lo actualizamos
    var idObj = event.target.id;
    var objEnArray = this.imagenesMesasObjetos.filter(x=>x.idAuxiliar == Number(idObj.split("obj")[1]))[0]; //el array de mesas solo se utiliza para el grid, por lo que basta con actualizarlo aquí
    objEnArray.posX = newLeft;
    objEnArray.posY = newTop;
    objEnArray.zIndex = AuxZindex;
    var objEnArrayTMP = this.listaMesasComedor.filter(x=>x.numeromesa == Number(idObj.split("obj")[1]))[0]; //el array de mesas solo se utiliza para el grid, por lo que basta con actualizarlo aquí
    objEnArrayTMP.posX = newLeft;
    objEnArrayTMP.posY = newTop;
    //Ahora comprobamos si se ha pasado del límite
    var auxiArray = this.imagenesMesasObjetos.filter(x=>x.idAuxiliar == Number(idObj.split("obj")[1]));
    auxiArray = auxiArray.sort((a, b) => (Number(a.zIndex) > Number(b.zIndex) ? 1 : -1)); //ordenamos el array por los zindex
    var encontrado = false; //buscamos si alguno se pasa y si lo hace los ponemos bien
    for(var i = 0; i< auxiArray.length && !encontrado; i++){
      if(auxiArray[i].zIndex>=2000){
         encontrado = true;
         this.reordenarZindexes();
      }
    }
    event.target.style.zIndex = AuxZindex;
  }

  //función para el resize del elemento
  resize(event, ui) {
    //Seleccionamos el elemento que tenga activo el resize
    if(document.getElementsByClassName("comedores-imagenSeleccionada").length == 1) //Solo debería haber uno
    {
      document.getElementsByClassName("comedores-imagenSeleccionada")[0].classList.remove("comedores-imagenSeleccionada");
    }
    event.target.classList.add("comedores-imagenSeleccionada");
    this.seleccionados = [];

    //Calculamos su nuevo width and height
    var changeWidth = ui.size.width - ui.originalSize.width;
    var newWidth = ui.originalSize.width + changeWidth;

    var changeHeight = ui.size.height - ui.originalSize.height;
    var newHeight = ui.originalSize.height + changeHeight;

    //Y los asignamos
    ui.size.width = newWidth;
    ui.size.height = newHeight;

    //Ahora obtenemos el objeto en el array y lo actualizamos
    var idObj = event.target.id;
    var objEnArray = this.imagenesMesasObjetos.filter(x=>x.idAuxiliar == Number(idObj.split("obj")[1]))[0]; //el array de mesas solo se utiliza para el grid, por lo que basta con actualizarlo aquí
    objEnArray.ancho = newWidth;
    objEnArray.alto = newHeight;
  }

  onClickCancelar(e) {
    e.preventDefault(); //para prevenir que entre al submit
    this.atras();
  }

  atras(){
    this.router.navigate(["/comedores",{idorg:this.idOrg,idrestaurante:this.idRestaurante}]);
  }

  onSubmit() {
    if(!this.isAddMode){ //es editar un comedor que ya existe
      if(this.imagenesMesasObjetos.length > 0 && this.imagenesMesasObjetos[0].eliminado == undefined) //Si no es undefined significa que ya existe uno con eliminado, por lo que no necesitamos ponerlo
        this.imagenesMesasObjetos[0].eliminado=false; //lo ponemos a false porque no está eliminado, solo queremos que la columna exista de cara a la api
      //Todos los cambios de movimientos se han hecho en el array de imagenesMesasObjetos, por lo que se usa ese para actualizar los datos

          if(this.imagenesMesasObjetos.length > 0){
            this.restauranteService.updateComedor(this.imagenesMesasObjetos).subscribe((result2: any) => {
              if(!result2.error){
                this.alertService.success(this.translateService.instant('ok'), { keepAfterRouteChange: true });
                this.atras();
              }else{
                this.alertService.error(this.translateService.instant('error'));
              }
            });
          }
          else{
            this.alertService.success(this.translateService.instant('ok'), { keepAfterRouteChange: true });
            this.atras();
          }

    }else{//es crear un comedor nuevo
      if(this.nombre == ""){
        this.errorNombreVacio = true;
        this.alertService.error(this.translateService.instant('debeAsignarseUnNombre'));
        return;
      }
    //AutoCompletado de Idiomas
    //seleccionado
    let seleccionado: ComedorTrad = new ComedorTrad();
    let encontrado: boolean = false;
    this.listaTraduccionesEnviar.forEach((trad) => {
      if (trad.filled && !encontrado) {
        seleccionado = trad;
        encontrado = true;
        return;
      }
    });
    //Fill UnFilled
    this.listaTraduccionesEnviar.forEach((trad) => {
      if (!trad.filled) {
        trad.nombre = seleccionado.nombre;
      }
    });
    console.log(this.listaTraduccionesEnviar);

        if(this.imagenesMesasObjetos.length > 0 && this.imagenesMesasObjetos[0].eliminado == undefined) //Si no es undefined significa que ya existe uno con eliminado, por lo que no necesitamos ponerlo
        this.imagenesMesasObjetos[0].eliminado=false; //lo ponemos a false porque no está eliminado, solo queremos que la columna exista de cara a la api
        //Todos los cambios de movimientos se han hecho en el array de imagenesMesasObjetos, por lo que se usa ese para actualizar los datos
        let comedor:Comedor=new Comedor();
        comedor.nombre=this.nombre;
        comedor.alto = this.largo;
        comedor.ancho= this.ancho;
        comedor.idRestaurante = this.idRestaurante;
        comedor.listaTraducciones = this.listaTraduccionesEnviar;
        comedor.listaMesaComedor = this.listaMesasComedor;
        console.log('MESAS que se envian:');
        console.log(this.listaMesasComedor);
        this.restauranteService.createComedor(comedor).subscribe((result: any) => {
          if(!result.error){
              this.alertService.success(this.translateService.instant('ok'), { keepAfterRouteChange: true });
          }else{
            this.alertService.error(this.translateService.instant('error'));
          }
        });
    }
  }

  onClickImage(image){
    console.log('Imagen Seleccionada');
    if(document.getElementsByClassName("comedores-imagenSeleccionada").length == 1) //Solo debería haber uno
    {
      document.getElementsByClassName("comedores-imagenSeleccionada")[0].classList.remove("comedores-imagenSeleccionada");
    }
    image.classList.add("comedores-imagenSeleccionada");
    var auxiliar = this.imagenesMesasObjetos.filter(x=>Number(x.idAuxiliar)== Number(image.id))[0];
    if(auxiliar == undefined){
      auxiliar = null;
    }else{
      if (Number(auxiliar.numero)!=0){
        //es mesa
        this.seleccionados = [];
        this.seleccionados.push(auxiliar.idAuxiliar);
      }
    }
  }

  cellClick(e){
    var images = Array.from(document.getElementsByTagName("img")); //tenemos todas las imágenes
    if(images.filter(x=> x.id== e.dataItem.idAuxiliar.toString()).length>0){ //la imagen de la mesa existe
      var image = images.filter(x=> x.id== e.dataItem.idAuxiliar.toString())[0];
      if(document.getElementsByClassName("comedores-imagenSeleccionada").length == 1) //Solo debería haber uno
      {
        document.getElementsByClassName("comedores-imagenSeleccionada")[0].classList.remove("comedores-imagenSeleccionada");
      }
      image.classList.add("comedores-imagenSeleccionada");
    }
  }

  fondoClick(){
    if(document.getElementsByClassName("comedores-imagenSeleccionada").length == 1) //Solo debería haber uno
    {
      document.getElementsByClassName("comedores-imagenSeleccionada")[0].classList.remove("comedores-imagenSeleccionada");
    }
  }

  public showGridTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN')
      && element.offsetWidth < element.scrollWidth) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

  onClickRotar(){
    var elementoARotar;
    if(document.getElementsByClassName("comedores-imagenSeleccionada").length==1){//bien porque solo debería haber uno seleccionado
      elementoARotar = document.getElementsByClassName("comedores-imagenSeleccionada")[0];
      var elementoArray = this.imagenesMesasObjetos.filter(x=>x.idAuxiliar == Number(elementoARotar.id))[0];
      var AuxZindex;
      if(elementoArray.zIndex != undefined){
        if(this.buscarElementoZIndex(elementoArray.zIndex.toString()) !== null){
          AuxZindex = (Number(this.buscarElementoZIndex(elementoArray.zIndex.toString())) + 1).toString();
        }else{
          AuxZindex = elementoArray.zIndex;
        }
      }else{
        AuxZindex= "0";
      }
      elementoArray.zIndex = AuxZindex;
      //Ahora comprobamos si se ha pasado del límite
      var auxiArray = this.imagenesMesasObjetos.filter(x=>x.idAuxiliar == Number(elementoARotar.id));
      auxiArray = auxiArray.sort((a, b) => (Number(a.zIndex) > Number(b.zIndex) ? 1 : -1)); //ordenamos el array por los zindex
      var encontrado = false; //buscamos si alguno se pasa y si lo hace los ponemos bien
      for(var i = 0; i< auxiArray.length && !encontrado; i++){
        if(auxiArray[i].zIndex>=2000){
          encontrado = true;
          this.reordenarZindexes();
        }
      }
      //x e y siempre se van a mantener, solo cambian las dimensiones
      var img;
      if(elementoArray.rotar == 0 || elementoArray.rotar == null){
        img = new Image();
        //le asignamos el enlace a la imagen auxiliar
        img.src = elementoArray.mesa; //se utiliza mesa porque se nunca cambia
        img.width = elementoArray.ancho;
        img.height = elementoArray.alto;
        img.elemento = elementoArray;
        // Creamos un canvas para rotarla
        let canvas = document.createElement("canvas");
        var that = this;
        // Hay que esperar a que la imagen se cargue
        img.onload = function(){
            //Cogemos el contexto del canvas
            let ctx = canvas.getContext("2d");	

            // Le asignamos el tamaño
            canvas.width = img.height;
            canvas.height = img.width;
            //Ponemos el contexto en el centro del canvas
            ctx.translate(canvas.width / 2,canvas.height / 2);

            // Rotamos la imagen
            ctx.rotate(90*(Math.PI/180));
			
            ctx.drawImage(img, -img.width / 2, -img.height / 2, img.width, img.height); //y la pintamos en el canvas
            var imagenTransformada = that.convertCanvasToImage(canvas);
            img.elemento.imagen = imagenTransformada.src;
            img.elemento.rotar=1;
            img.elemento.ancho = imagenTransformada.width;
            img.elemento.alto = imagenTransformada.height;
            
            that.hacerDraggables(that);
        }
      }else if(elementoArray.rotar == 1){
        img = new Image();
        //le asignamos el enlace a la imagen auxiliar
        img.src =  elementoArray.mesa; //se utiliza mesa porque se nunca cambia
        img.width = elementoArray.ancho;
        img.height = elementoArray.alto;
        img.elemento = elementoArray;
        // Creamos un canvas para rotarla
        let canvas = document.createElement("canvas");
        var that = this;
        // Hay que esperar a que la imagen se cargue
        img.onload = function(){
            //Cogemos el contexto del canvas
            let ctx = canvas.getContext("2d");	

            // Le asignamos el tamaño
            canvas.width = img.width;
            canvas.height = img.height;
            //Ponemos el contexto en el centro del canvas
            ctx.translate(canvas.width / 2,canvas.height / 2);

            // Rotamos la imagen
            ctx.rotate(180*(Math.PI/180));
			
            ctx.drawImage(img, -img.width / 2, -img.height / 2, img.width, img.height); //y la pintamos en el canvas
            var imagenTransformada = that.convertCanvasToImage(canvas);
            img.elemento.imagen = imagenTransformada.src;
            img.elemento.rotar=2;
            img.elemento.ancho = imagenTransformada.width;
            img.elemento.alto = imagenTransformada.height;
            that.hacerDraggables(that);
        }
      }else if(elementoArray.rotar == 2){
        img = new Image();
        //le asignamos el enlace a la imagen auxiliar
        img.src = elementoArray.mesa; //se utiliza mesa porque se nunca cambia
        img.width = elementoArray.ancho;
        img.height = elementoArray.alto;
        img.elemento = elementoArray;
        // Creamos un canvas para rotarla
        let canvas = document.createElement("canvas");
        var that = this;
        // Hay que esperar a que la imagen se cargue
        img.onload = function(){
            //Cogemos el contexto del canvas
            let ctx = canvas.getContext("2d");	

            // Le asignamos el tamaño
            canvas.width = img.height;
            canvas.height = img.width;
            //Ponemos el contexto en el centro del canvas
            ctx.translate(canvas.width / 2,canvas.height / 2);

            // Rotamos la imagen
            ctx.rotate(270*(Math.PI/180));
			
            ctx.drawImage(img, -img.width / 2, -img.height / 2, img.width, img.height); //y la pintamos en el canvas
            var imagenTransformada = that.convertCanvasToImage(canvas);
            img.elemento.imagen = imagenTransformada.src;
            img.elemento.rotar=3;
            img.elemento.ancho = imagenTransformada.width;
            img.elemento.alto = imagenTransformada.height;
            that.hacerDraggables(that);
        }
      } else if(elementoArray.rotar == 3){
        img = new Image();
        //le asignamos el enlace a la imagen auxiliar
        img.src =  elementoArray.mesa; //se utiliza mesa porque se nunca cambia
        img.width = elementoArray.ancho;
        img.height = elementoArray.alto;
        img.elemento = elementoArray;
        // Creamos un canvas para rotarla
        let canvas = document.createElement("canvas");
        var that = this;
        // Hay que esperar a que la imagen se cargue
        img.onload = function(){
            //Cogemos el contexto del canvas
            let ctx = canvas.getContext("2d");	

            // Le asignamos el tamaño
            canvas.width = img.width;
            canvas.height = img.height;
            //Ponemos el contexto en el centro del canvas
            ctx.translate(canvas.width / 2,canvas.height / 2);

            // Rotamos la imagen
            ctx.rotate(360*(Math.PI/180));
			
            ctx.drawImage(img, -img.width / 2, -img.height / 2, img.width, img.height); //y la pintamos en el canvas
            var imagenTransformada = that.convertCanvasToImage(canvas);
            img.elemento.imagen = imagenTransformada.src;
            img.elemento.rotar=0;
            img.elemento.ancho = imagenTransformada.width;
            img.elemento.alto = imagenTransformada.height;
            that.hacerDraggables(that);
        }
      }
    }//else
      //o no había ninguno seleccionado o algo ha fallado
    
  }

  //Funciones modal mesa
  onClickAgregarMesa(){
    this.restauranteService.getMesas(0,true).subscribe((result) => {
      this.mesasModal = result.data;
      this.modalReference = this.modalService.open(this.popupAgregarMesa, { backdrop: 'static', size: 'xxl', keyboard: false, centered: true });
    });
    
  }

  onClickNuevaMesa(){
    this.codigoModalMesas = 0;
    this.mesaSeleccionadaModal = null;
    this.nombre1MesaModalError= true;
    this.submittedModalMesas = false;
    this.nombre1MesaModal = "";
    this.nombre2MesaModal = "";
    this.nombre2MesaModalError= true;
    this.numComensalesMesasModal= 1;
    this.errorComensalesModalMesas = false;
    this.imageToShowModalMesas = null;
    this.archivoModalMesas = null;
    this.errorImagenModalMesas = true;
    this.isAddModeModalMesas =true;
    this.modalReference.close();
    this.modalReference2 = this.modalService.open(this.popupNuevaEditarMesa, { backdrop: 'static', size: 's', keyboard: false, centered: true });
    
  }

  onClickEditarMesa(){
    //Rellenar datos mesa a editar, todos los errores son false porque tenemos los datos
    if(this.mesaSeleccionadaModal!= null){
      this.codigoModalMesas = this.mesaSeleccionadaModal.codigo;
      this.nombre1MesaModalError= false;
      this.submittedModalMesas = false;
      this.nombre1MesaModal = this.mesaSeleccionadaModal.nombre1;
      this.nombre2MesaModal = this.mesaSeleccionadaModal.nombre2;
      this.nombre2MesaModalError= false;
      this.numComensalesMesasModal= this.mesaSeleccionadaModal.comensales;
      this.errorComensalesModalMesas = false;
      this.imageToShowModalMesas = this.mesaSeleccionadaModal.urlFoto;
      this.errorImagenModalMesas = false;
      this.isAddModeModalMesas =false;
      this.modalReference.close();
      this.modalReference2 = this.modalService.open(this.popupNuevaEditarMesa, { backdrop: 'static', size: 's', keyboard: false, centered: true });
    }
  }

  onClickEliminarMesa(){
    this.modalReference3 = this.modalService.open(this.popupBorrarMesa, { backdrop: 'static', size: 's', keyboard: false, centered: true });
  }

  btnBorrarAceptarMesa(){
    this.restauranteService.deleteMesas(this.mesaSeleccionadaModal.codigo).subscribe((result: any)=>{
      if(result==-1){
        this.alertService.error(this.translateService.instant('error'));
      }
      else{
        this.alertService.success(this.translateService.instant('ok'), { keepAfterRouteChange: true });
        this.restauranteService.getMesas(this.idComedor,true).subscribe((result) => {
          this.mesasModal = result;
        });
      }
      this.mesaSeleccionadaModal = null;
    });
    this.modalReference3.close();
  }

  mesaClick(mesa){
    var mesaElement = document.getElementById("mesa"+this.mesasModal.indexOf(this.mesasModal.filter(x=> x.id == mesa.id)[0])).parentElement;
    if(document.getElementsByClassName("comedores-imagenSeleccionadaModalMesas").length == 1) //Solo debería haber uno
    {
      document.getElementsByClassName("comedores-imagenSeleccionadaModalMesas")[0].classList.remove("comedores-imagenSeleccionadaModalMesas");
    }
    mesaElement.classList.add("comedores-imagenSeleccionadaModalMesas"); 
    this.mesaSeleccionadaModal = mesa;
  }

  btnCancelarMesa(){
    if(document.getElementsByClassName("comedores-imagenSeleccionadaModalMesas").length == 1) //Solo debería haber uno
    {
      document.getElementsByClassName("comedores-imagenSeleccionadaModalMesas")[0].classList.remove("comedores-imagenSeleccionadaModalMesas");
    }
    this.mesaSeleccionadaModal = null;
    this.modalReference.close();
  }

  btnCancelarMesa2(){
    this.modalReference2.close();
    this.modalReference = this.modalService.open(this.popupAgregarMesa, { backdrop: 'static', size: 'xxl', keyboard: false, centered: true });
  }

  btnAceptarMesa(){
    //Hay que poner la mesa en el comedor
    if(this.mesaSeleccionadaModal!= null){

      //La primera vez que se inserta el objeto, se inserta con el tamaño de la imagen
      var ancho, alto;
      var img = new Image();
      var that = this;
      img.src = this.mesaSeleccionadaModal.urlFoto;
      img.width=img.width/10;
      img.height= img.height/10;
      img.addEventListener('load',function(){ //no tenemos los datos hasta que la imagen no se carga
          ancho=img.width;
          alto=img.height;
          if(alto > $("#canvas").height()){
            that.alertService.error(that.translateService.instant('imagenDemasiadoGrande'));
          }else if(ancho >$("#canvas").width()){
            that.alertService.error(that.translateService.instant('imagenDemasiadoGrande'));
          }else{
             //Creamos la nueva mesa
          var mesaAux = 
          {alto: alto,
            ancho: ancho,
            codcomedor: that.id,
            codigo: -1,
            idAuxiliar: that.getMaxCodigo(), //necesitamos utilizar un código auxiliar porque se seleccionan por código y los nuevos no tienen
            codmesa: that.mesaSeleccionadaModal.codigo, //este no existe, pero como solo se usará cuando se inserten, no dará problemas
            comensales: that.mesaSeleccionadaModal.comensales,
            imagen: that.mesaSeleccionadaModal.urlFoto,
            mesa: that.mesaSeleccionadaModal.urlFoto,
            nombre: that.mesaSeleccionadaModal.nombre,
            numero: that.getMaxNumero(),
            rotar: 0, //Al comienzo no está rotada nunca
            posX: 0,
            posY: 0,
            zIndex: (Number(that.buscarElementoZIndex("0")) + 1).toString()
          } 
          let mesaTmp = new MesaComedor();
          mesaTmp.numeromesa= mesaAux.numero;
          mesaTmp.idComedor = that.idComedor;
          mesaTmp.posX = mesaAux.posX;
          mesaTmp.posY = mesaAux.posY;
          mesaTmp.urlFoto = mesaAux.imagen;
          that.listaMesasComedor.push(mesaTmp);
          if(that.comensales == undefined)
            that.comensales = 0;
          that.comensales += that.mesaSeleccionadaModal.comensales;
          that.mesas.push(mesaAux);
          that.imagenesMesasObjetos.push(mesaAux);
          that.modalReference.close();
          that.hacerDraggables(that);
          }
         
      });
    }
  }

  
  

  btnAceptarMesa2(){
    this.submittedModalMesas = true;
    if(this.archivoModalMesas == null && this.imageToShowModalMesas == null){
      this.errorImagenModalMesas = true;
    } else{
      this.errorImagenModalMesas = false;
    }
    if(this.numComensalesMesasModal == null || this.numComensalesMesasModal == 0){
      this.errorComensalesModalMesas = true;
    } else{
      this.errorComensalesModalMesas = false;
    }

    if(this.nombre1MesaModal == null || this.nombre1MesaModal == ""){
      this.nombre1MesaModalError = true;
    } else{
      this.nombre1MesaModalError = false;
    }

    if(this.nombre2MesaModal == null || this.nombre2MesaModal == ""){
      this.nombre2MesaModalError = true;
    } else{
      this.nombre2MesaModalError = false;
    }
    
    if(this.submittedModalMesas && this.errorImagenModalMesas)
      return;
    
    if(this.submittedModalMesas && this.errorComensalesModalMesas)
      return;

    if(this.submittedModalMesas && this.nombre1MesaModalError)
      return;

    if(this.submittedModalMesas && this.nombre2MesaModalError)
      return;

    if(this.isAddModeModalMesas){
      //insertar
      let mesa:Mesa=new Mesa();
      this.restauranteService.createMesa(mesa).subscribe((result: any)=>{
          if(result.error){
            this.alertService.error(this.translateService.instant('error'));
          }
          else{
            this.alertService.success(this.translateService.instant('ok'), { keepAfterRouteChange: true });
            this.modalReference2.close();
            this.onClickAgregarMesa();
          }
      });
    } else{
      //actualizar
      let mesa:Mesa=new Mesa();
      this.restauranteService.updateMesa(mesa).subscribe((result: any)=>{
        if(result.error){
          this.alertService.error(this.translateService.instant('error'));
        }
        else{
          this.alertService.success(this.translateService.instant('ok'), { keepAfterRouteChange: true });
          this.modalReference2.close();
            this.onClickAgregarMesa();
        }
      }); 
    }
  }

  //funciones imagen mesa
  archivoSeleccionadoModalMesas(e: SelectEvent) {
    var th = this;

    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.archivoModalMesas !== "" && th.archivoModalMesas !== null) archivoBase64 = await th.toBase64(th.archivoModalMesas[0]);
      else archivoBase64 = "";
      th.imageToShowModalMesas = archivoBase64;
    }, 500);

  }

  archivoEliminadoModalMesas(e: RemoveEvent) {
    this.imageToShowModalMesas = "";
  }

  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  //Fin funciones modal mesa

  //funciones modal objeto
  onClickAgregarObjeto(){
    this.restauranteService.getMesas(this.idComedor,false).subscribe((result) => {
      this.objetosModal = result;
      this.modalReference = this.modalService.open(this.popupAgregarObjeto, { backdrop: 'static', size: 'xxl', keyboard: false, centered: true });
    });
  }

  onClickNuevoObjeto(){
    // this.mesasObjetosService.getCodigoLibre().subscribe((resultCodigo: any)=>{
    //   //Inicializar variables, todos los errores son true porque no tenemos datos. El de comensales es false porque le ponemos mínimo 1
    //   this.codigoModalObjetos = resultCodigo[0].codigo;
    //   this.objetoSeleccionadoModal = null;
    //   this.nombre1ObjetoModalError= true;
    //   this.submittedModalObjeto = false;
    //   this.nombre1ObjetoModal = "";
    //   this.nombre2ObjetoModal = "";
    //   this.nombre2ObjetoModalError= true;
    //   this.imageToShowModalObjeto = null;
    //   this.archivoModalObjeto = null;
    //   this.errorImagenModalObjeto = true;
    //   this.isAddModeModalObjeto =true;
    //   this.modalReference.close();
    //   this.modalReference2 = this.modalService.open(this.popupNuevoEditarObjeto, { backdrop: 'static', size: 's', keyboard: false, centered: true });
    // });
  }

  onClickEditarObjeto(){
    //Rellenar datos mesa a editar, todos los errores son false porque tenemos los datos
    if(this.objetoSeleccionadoModal!= null){
      this.codigoModalObjetos = this.objetoSeleccionadoModal.codigo;
      this.nombre1ObjetoModalError= false;
      this.submittedModalObjeto = false;
      this.nombre1ObjetoModal = this.objetoSeleccionadoModal.nombre1;
      this.nombre2ObjetoModal = this.objetoSeleccionadoModal.nombre2;
      this.nombre2ObjetoModalError= false;
      this.imageToShowModalObjeto = this.objetoSeleccionadoModal.urlFoto;
      this.errorImagenModalObjeto = false;
      this.isAddModeModalObjeto =false;
      this.modalReference.close();
      this.modalReference2 = this.modalService.open(this.popupNuevoEditarObjeto, { backdrop: 'static', size: 's', keyboard: false, centered: true });
    }
  }

  onClickEliminarObjeto(){
    this.modalReference3 = this.modalService.open(this.popupBorrarObjeto, { backdrop: 'static', size: 's', keyboard: false, centered: true });
  }

  btnBorrarAceptarObjeto(){
    this.restauranteService.deleteMesas(this.objetoSeleccionadoModal.codigo).subscribe((result: any)=>{
      if(result==-1){
        this.alertService.error(this.translateService.instant('error'));
      }
      else{
        this.alertService.success(this.translateService.instant('ok'), { keepAfterRouteChange: true });
        this.restauranteService.getMesas(this.idComedor,false).subscribe((result) => {
          this.objetosModal = result;
        });
      }
      this.objetoSeleccionadoModal = null;
    });
    this.modalReference3.close();
  }

  objetoClick(objeto){
    var objetoElement = document.getElementById("objeto"+this.objetosModal.indexOf(this.objetosModal.filter(x=> x.codigo == objeto.codigo)[0])).parentElement;
    if(document.getElementsByClassName("comedores-imagenSeleccionadaModalMesas").length == 1) //Solo debería haber uno
    {
      document.getElementsByClassName("comedores-imagenSeleccionadaModalMesas")[0].classList.remove("comedores-imagenSeleccionadaModalMesas");
    }
    objetoElement.classList.add("comedores-imagenSeleccionadaModalMesas"); 
    this.objetoSeleccionadoModal = objeto;
  }

  btnCancelarObjeto(){
    if(document.getElementsByClassName("comedores-imagenSeleccionadaModalMesas").length == 1) //Solo debería haber uno
    {
      document.getElementsByClassName("comedores-imagenSeleccionadaModalMesas")[0].classList.remove("comedores-imagenSeleccionadaModalMesas");
    }
    this.objetoSeleccionadoModal = null;
    this.modalReference.close();
  }

  btnCancelarObjeto2(){
    this.modalReference2.close();
    this.modalReference = this.modalService.open(this.popupAgregarObjeto, { backdrop: 'static', size: 'xxl', keyboard: false, centered: true });
  }

  btnAceptarObjeto(){
    //Hay que poner el objeto en el comedor
    if(this.objetoSeleccionadoModal!= null){

      //La primera vez que se inserta el objeto, se inserta con el tamaño de la imagen
      var ancho, alto;
      var img = new Image();
      var that = this;
      img.src = this.objetoSeleccionadoModal.urlFoto;
      img.addEventListener('load',function(){ //no tenemos los datos hasta que la imagen no se carga
          ancho=img.width;
          alto=img.height;
          if(alto > $("#canvas").height()){
            that.alertService.error(that.translateService.instant('imagenDemasiadoGrande'));
          }else if(ancho >$("#canvas").width()){
            that.alertService.error(that.translateService.instant('imagenDemasiadoGrande'));
          }else{
            //Creamos el nuevo objeto
            var objetoAux = 
            {alto: alto,
            ancho: ancho,
            codcomedor: that.id,
            codigo: -1,
            idAuxiliar: that.getMaxCodigo(), //necesitamos utilizar un código auxiliar porque se seleccionan por código y los nuevos no tienen
            codmesa: that.objetoSeleccionadoModal.codigo, //este no existe, pero como solo se usará cuando se inserten, no dará problemas
            comensales: 0,
            imagen: that.objetoSeleccionadoModal.urlFoto,
            mesa: that.objetoSeleccionadoModal.urlFoto,
            nombre1: that.objetoSeleccionadoModal.nombre1,
            nombre2: that.objetoSeleccionadoModal.nombre2,
            numero: 0,
            rotar: 0, //Al comienzo no está rotada nunca
            x: 0,
            y: 0,
            zIndex: (Number(that.buscarElementoZIndex("0")) + 1).toString()
          } 
          that.objetos.push(objetoAux);
          that.imagenesMesasObjetos.push(objetoAux);
          that.modalReference.close();
          that.hacerDraggables(that);
          }
          
      });
    }
  }
  

  btnAceptarObjeto2(){
    this.submittedModalObjeto = true;
    if(this.archivoModalObjeto == null && this.imageToShowModalObjeto == null){
      this.errorImagenModalObjeto = true;
    } else{
      this.errorImagenModalObjeto = false;
    }

    if(this.nombre1ObjetoModal == null || this.nombre1ObjetoModal == ""){
      this.nombre1ObjetoModalError = true;
    } else{
      this.nombre1ObjetoModalError = false;
    }

    if(this.nombre2ObjetoModal == null || this.nombre2ObjetoModal == ""){
      this.nombre2ObjetoModalError = true;
    } else{
      this.nombre2ObjetoModalError = false;
    }
    
    if(this.submittedModalObjeto && this.errorImagenModalObjeto)
      return;

    if(this.submittedModalMesas && this.nombre1ObjetoModalError)
      return;

    if(this.submittedModalMesas && this.nombre2ObjetoModalError)
      return;

    if(this.isAddModeModalObjeto){
      //insertar
      let mesa:Mesa=new Mesa();
      this.restauranteService.createMesa(mesa).subscribe((result: any)=>{
          if(result.error){
            this.alertService.error(this.translateService.instant('error'));
          }
          else{
            this.alertService.success(this.translateService.instant('ok'), { keepAfterRouteChange: true });
            this.modalReference2.close();
            this.onClickAgregarObjeto();
          }
      });
    } else{
      //actualizar
      let mesa:Mesa=new Mesa();
      this.restauranteService.updateMesa(mesa).subscribe((result: any)=>{
        if(result.error){
          this.alertService.error(this.translateService.instant('error'));
        }
        else{
          this.alertService.success(this.translateService.instant('ok'), { keepAfterRouteChange: true });
          this.modalReference2.close();
            this.onClickAgregarObjeto();
        }
      }); 
    }
  }

  archivoSeleccionadoModalObjetos(e: SelectEvent) {
    var th = this;

    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.archivoModalObjeto !== "" && th.archivoModalObjeto !== null) archivoBase64 = await th.toBase64(th.archivoModalObjeto[0]);
      else archivoBase64 = "";
      th.imageToShowModalObjeto = archivoBase64;
    }, 500);

  }

  archivoEliminadoModalObjetos(e: RemoveEvent) {
    this.imageToShowModalObjeto = "";
  }

  //Fin funciones modal objeto

  //Funciones modal fondo
  onClickCambiarFondo(){
    // this.fondosService.GetFondos().subscribe((result) => {
    //   this.verExpandidoExteriorModalFondo = false; //por defecto es false
    //   this.fondosModal = result;
    //   this.modalReference = this.modalService.open(this.popupAgregarFondo, { backdrop: 'static', size: 'xxl', keyboard: false, centered: true });
    // });
  }

  onClickNuevoFondo(){
    // this.fondosService.getCodigoLibre().subscribe((resultCodigo: any)=>{
    //   //Inicializar variables, todos los errores son true porque no tenemos datos. 
    //   this.codigoModalFondos = resultCodigo[0].codigo;
    //   this.fondoSeleccionadoModal = null;
    //   this.submittedModalFondo = false;
    //   this.imageToShowModalFondo = null;
    //   this.archivoModalFondo = null;
    //   this.errorImagenModalFondo = true;
    //   this.verExpandidoModalFondo = false; //el default es mosaico
    //   this.isAddModeModalFondo =true;
    //   this.modalReference.close();
    //   this.modalReference2 = this.modalService.open(this.popupNuevoEditarFondo, { backdrop: 'static', size: 's', keyboard: false, centered: true });
    // });
  }

  onClickEditarFondo(){
    //Rellenar datos fondo a editar, todos los errores son false porque tenemos los datos
    if(this.fondoSeleccionadoModal!= null){
      this.codigoModalFondos = this.fondoSeleccionadoModal.codigo;
      this.submittedModalFondo = false;
      this.imageToShowModalFondo = this.fondoSeleccionadoModal.urlFoto;
      this.errorImagenModalFondo = false;
      this.isAddModeModalFondo =false;
      this.modalReference.close();
      this.modalReference2 = this.modalService.open(this.popupNuevoEditarFondo, { backdrop: 'static', size: 's', keyboard: false, centered: true });
    }
  }

  onClickEliminarFondo(){
    this.modalReference3 = this.modalService.open(this.popupBorrarFondo, { backdrop: 'static', size: 's', keyboard: false, centered: true });
  }

  btnBorrarAceptarFondo(){
    // this.fondosService.Delete(this.fondoSeleccionadoModal.codigo).subscribe((result: any)=>{
    //   if(result==-1){
    //     this.alertService.error(this.translateService.instant('error'));
    //   }
    //   else{
    //     this.alertService.success(this.translateService.instant('ok'), { keepAfterRouteChange: true });
    //     this.fondosService.GetFondos().subscribe((result) => {
    //       this.fondosModal = result;
    //     });
    //   }
    //   this.fondoSeleccionadoModal = null;
    // });
    // this.modalReference3.close();
  }

  fondoClickModal(fondo){
    var fondoElement = document.getElementById("fondo"+this.fondosModal.indexOf(this.fondosModal.filter(x=> x.codigo == fondo.codigo)[0])).parentElement;
    if(document.getElementsByClassName("comedores-imagenSeleccionadaModalMesas").length == 1) //Solo debería haber uno
    {
      document.getElementsByClassName("comedores-imagenSeleccionadaModalMesas")[0].classList.remove("comedores-imagenSeleccionadaModalMesas");
    }
    fondoElement.classList.add("comedores-imagenSeleccionadaModalMesas"); 
    this.fondoSeleccionadoModal = fondo;
  }

  btnCancelarFondo(){
    if(document.getElementsByClassName("comedores-imagenSeleccionadaModalMesas").length == 1) //Solo debería haber uno
    {
      document.getElementsByClassName("comedores-imagenSeleccionadaModalMesas")[0].classList.remove("comedores-imagenSeleccionadaModalMesas");
    }
    this.fondoSeleccionadoModal = null;
    this.modalReference.close();
  }

  btnCancelarFondo2(){
    this.modalReference2.close();
    this.modalReference = this.modalService.open(this.popupAgregarFondo, { backdrop: 'static', size: 'xxl', keyboard: false, centered: true });
  }

  btnAceptarFondo(){
    // //Hay que poner el fondo en el comedor
    // if(this.fondoSeleccionadoModal!= null){
    //   this.fondosService.getFondoById(this.fondoSeleccionadoModal.codigo).subscribe((result: any)=>{
    //     this.fondo = result[0].imagenBase64;
    //     this.codFondo = result[0].codigo;
    //     this.expandido = this.verExpandidoExteriorModalFondo;
    //     if(!this.expandido){
    //       this.imagenes = [];
    //       for (var i = 0; i < 20; i++) {
    //         this.imagenes.push(this.fondo);
    //       }
    //     }else{
    //       this.imagenes = [this.fondo];
    //     }
        
    //     this.modalReference.close();
    //   });
    // }
  }
  

  btnAceptarFondo2(){
    // this.submittedModalFondo = true;
    // if(this.archivoModalFondo == null && this.imageToShowModalFondo == null){
    //   this.errorImagenModalFondo = true;
    // } else{
    //   this.errorImagenModalFondo = false;
    // }
    
    // if(this.submittedModalFondo && this.errorImagenModalFondo)
    //   return;

    // if(this.isAddModeModalFondo){
    //   //insertar
    //   this.fondosService.insertFondo(this.codigoModalFondos, this.imageToShowModalFondo, this.verExpandidoModalFondo).subscribe((result: any)=>{
    //       if(result.error){
    //         this.alertService.error(this.translateService.instant('error'));
    //       }
    //       else{
    //         this.alertService.success(this.translateService.instant('ok'), { keepAfterRouteChange: true });
    //         this.modalReference2.close();
    //         this.onClickCambiarFondo();
    //       }
    //   });
    // } else{
    //   //actualizar
    //   this.fondosService.updateFondo(this.codigoModalFondos, this.imageToShowModalFondo, this.verExpandidoModalFondo).subscribe((result: any)=>{
    //     if(result.error){
    //       this.alertService.error(this.translateService.instant('error'));
    //     }
    //     else{
    //       this.alertService.success(this.translateService.instant('ok'), { keepAfterRouteChange: true });
    //       this.modalReference2.close();
    //         this.onClickCambiarFondo();
    //     }
    //   }); 
    // }
  }

  archivoSeleccionadoModalFondos(e: SelectEvent) {
    var th = this;

    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.archivoModalFondo !== "" && th.archivoModalFondo !== null) archivoBase64 = await th.toBase64(th.archivoModalFondo[0]);
      else archivoBase64 = "";
      th.imageToShowModalFondo = archivoBase64;
    }, 500);

  }

  archivoEliminadoModalFondos(e: RemoveEvent) {
    this.imageToShowModalFondo = "";
  }

  changeExpandirInterior(valor){
    this.verExpandidoModalFondo= valor;
  }

  changeExpandirExterior(valor){
    this.verExpandidoExteriorModalFondo= valor;
  }

  //Fin funciones modal fondo

  onClickEliminar(){
    var elementoARotar;
    if(document.getElementsByClassName("comedores-imagenSeleccionada").length==1){//bien porque solo debería haber uno seleccionado
      elementoARotar = document.getElementsByClassName("comedores-imagenSeleccionada")[0];
      var elementoArray = this.imagenesMesasObjetos.filter(x=>x.idAuxiliar == Number(elementoARotar.id))[0];
      if(elementoArray.id==-1){
        //Era nuevo y ahora se quiere eliminar, así que directamente se quita de los arrays
        this.imagenesMesasObjetos = this.imagenesMesasObjetos.filter(x=>x.idAuxiliar != elementoArray.idAuxiliar); //No utilizamos codigo porque se borrarían todos los nuevos
      }else{
        elementoArray.eliminado = true; //le metemos variable para eliminarse
      }
      //Podemos hacerlo así porque nunca estará al mismo tiempo en los dos arrays
      this.mesas = this.mesas.filter(x=>x.idAuxiliar != elementoArray.idAuxiliar);
      this.objetos = this.objetos.filter(x=>x.idAuxiliar != elementoArray.idAuxiliar);
      this.hacerDraggables(this);
    }
  }

 


  //auxiliares
  // Converts canvas to an image
  convertCanvasToImage(canvas) {
    var image = new Image();
    image.width = canvas.width;
    image.height = canvas.height;
    image.src = canvas.toDataURL("image/png");
    return image;
  }

  getMaxNumero(){ //Podemos hacer esto con el número, porque esta numeración se usa por comedor
    var maxNumero = 0;
    this.mesas.forEach(element => {
      if(element.numero>maxNumero)
        maxNumero = element.numero;
    });
    return maxNumero + 1;
  }

  getMaxCodigo(){
    var maxCodigo = 0;
    this.imagenesMesasObjetos.forEach(element => {
      if(element.idAuxiliar>maxCodigo)
        maxCodigo = element.idAuxiliar;
    });
    return maxCodigo + 1;
  }

  reordenarZindexes(){
    var zIndex = 0;
    this.imagenesMesasObjetos = this.imagenesMesasObjetos.sort((a, b) => (Number(a.zIndex) > Number(b.zIndex) ? 1 : -1)); //ordenamos el array por los zindex
    for(var i = 0; i< this.imagenesMesasObjetos.length; i++){
      this.imagenesMesasObjetos[i].zIndex=zIndex.toString();
      zIndex++;
    }
  }

  buscarElementoZIndex(zIndex){
    //Primero miramos si nos hemos pasado del tope
    var auxiArray = this.imagenesMesasObjetos.filter(x=>x.eliminado!== true);
    
     var maxZindex = 0;
    for(var i = 0; i< auxiArray.length; i++){
      if(Number(auxiArray[i].zIndex)>maxZindex){
        maxZindex = auxiArray[i].zIndex;
      }
    }
    return maxZindex;
  }

  actualizarDiasInactivos(valueEmitted){
    if(this.diasInactivos != valueEmitted)
      this.diasInactivos = valueEmitted;
  }

  actualizarDiasActivos(valueEmitted){
    if(this.diasActivos != valueEmitted)
      this.diasActivos = valueEmitted;
  }

  actualizarAno(valueEmitted){
    if(this.year != valueEmitted)
      this.year = valueEmitted;
  }

  btnAceptar(){
    this.comidaSelected = this.comidaSelectedModal;
    this.comedorSelected = this.comedorSelectedModal;
    //Gaudra los nuevos checkes
    // this.calendarioService.updateReglaCalendario1(this.year, this.comedorSelected, this.comidaSelected, this.lunes, this.martes, 
    //   this.miercoles, this.jueves, this.viernes, this.sabado, this.domingo).subscribe((result: any) =>{
    // });
    this.modalReference.close();
  }

btnAddTraduccion() {
  console.log('Comprobando datos');
  if (
    this.selectedIdioma == null ||
    this.nombre.length <= 0 
  ) {
    if (this.listaTraducciones.length <= 0) {
      this.alertService.info(
        this.translateService.instant('publicaciones.validacion')
      );
      return;
    }
  }
  console.log('Datos validados --->');
  if (!this.listaTraducciones.includes(this.selectedIdioma)) {
    this.listaTraducciones.push(this.selectedIdioma);

    this.listaTraduccionesEnviar.map((x) => {
      if (x.idIdioma == this.selectedIdioma.codigo) {
        x.filled = true;
        x.nombre = this.nombre;
      }
    });
  } else {
    console.log('Idioma ya añadido');
  }

  console.log(this.listaTraduccionesEnviar);
}

btnDeleteTraduccion(idioma: Idioma) {
  console.log(idioma);
  this.listaTraducciones = this.listaTraducciones.filter(
    (x) => x.codigo != idioma.codigo
  );

  this.listaTraduccionesEnviar.map((x) =>
    x.idIdioma == idioma.codigo ? (x.filled = false) : console.log()
  );

  console.log(this.listaTraduccionesEnviar);
}

}