import { Component, ViewChild,OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Usuario } from '@app/_models';
import { MenuService, UsuariosService } from '@app/_services';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { RestauranteService } from '@app/_services/restaurante.service';


@Component({
  selector: 'app-comedores',
  templateUrl: './comedores.component.html',
})
export class ComedoresComponent implements OnInit {
  user = this.usuariosService.userValue;
  navigationSubscription;
  public comedores: any = []; //Guarda todos las comedores
  public comedoresSeleccionados: any = []; //Guarda todos los comedores seleccionados
  @ViewChild(TooltipDirective) public tooltipDir: TooltipDirective;
  private translate: TranslateService;
  isDeleting = false;
  @ViewChild('popupBorrar') popupBorrar: NgbModalRef;
  @ViewChild('popupBorrarConReservas') popupBorrarConReservas: NgbModalRef;
  modalReference: NgbModalRef;
  idOrg:string;
  idRestaurante:number;

  constructor(private restauranteService: RestauranteService, 
      private menuService: MenuService, public router: Router, public route:ActivatedRoute,
      private translateService: TranslateService,
      private usuariosService: UsuariosService, private modalService: NgbModal) {    
        this.translate = translateService;        
        this.navigationSubscription = this.router.events.subscribe((e: any) => {
          // If it is a NavigationEnd event re-initalise the component
          if (e instanceof NavigationEnd) {
            if (this.router.url == '/comedores') { 
              this.menuService.titulo = this.translateService.instant('comedores');
              this.cargarDatos();
            }
          }
        });
    }
  
  ngOnInit(): void {
    this.idOrg = this.route.snapshot.params['idorg'];
    this.idRestaurante = this.route.snapshot.params['idrestaurante'];
    this.cargarDatos();
  }

  cargarDatos(){
    console.log('Loading comedores...');
    this.restauranteService.getComedores(this.idRestaurante).subscribe((result) => {
      console.log(result);
      this.comedores = result.data;
    });
  }

  public showGridTooltip(e: MouseEvent): void {
    const element = e.target as HTMLElement;
    if ((element.nodeName === 'TD' || element.nodeName === 'TH' || element.nodeName === 'SPAN')
      && element.offsetWidth < element.scrollWidth) {
      this.tooltipDir.toggle(element);
    } else {
      this.tooltipDir.hide();
    }
  }

  onClickNuevo(){
    this.router.navigate(['comedores-editar',{idorg:this.idOrg,idrestaurante:this.idRestaurante,idcomedor:-1}]);
  }

  onClickEliminar(){
    //Mirar si tiene reservas
    var ids: string;
    this.comedoresSeleccionados.forEach(element => {
      if (element > 0) {
        this.isDeleting = true;
        ids = "";
        this.comedoresSeleccionados.forEach(
          ap => {
            if (ids == "")
              ids += ap;
            else
              ids += ',' + ap;
          }
        );
      }
    });
  }

  cancelarModal(){
    this.modalReference.close();
  }

  btnBorrarAceptar(){
    this.comedoresSeleccionados.forEach(element => {
      if (element > 0) {
        this.isDeleting = true;
        var ids: string = "";
        this.comedoresSeleccionados.forEach(
          ap => {
            if (ids == "")
              ids += ap;
            else
              ids += ',' + ap;
          }
        );
        this.restauranteService.deleteComedores(ids).subscribe(() => {
          this.isDeleting = false;
          this.cargarDatos();
        });
      }
      this.modalReference.close();
    });
  }

  cellClick(e){
    if(e.columnIndex>0)
    this.router.navigate(['comedores-editar',{idorg:this.idOrg,idcomedor:e.dataItem.id}]);
  }
}