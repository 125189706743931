import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Recinto, Reserva, Sala } from '@app/_models/recinto';
import { environment } from '@environments/environment';
import { SchedulerEvent } from '@progress/kendo-angular-scheduler';
import { Observable } from 'rxjs';
import {
  Comedor,
  ComidaTipo,
  Mesa,
  ReservaPeticion,
  Restaurante,
} from '@app/_models/restaurante';
const baseUrl = `${environment.apiUrl}/restaurante`;

@Injectable({
  providedIn: 'root',
})
export class RestauranteService {
  constructor(private http: HttpClient) {}

  //Restaurantes
  public getRestaurantesFromEnte(idOrg: string): Observable<any> {
    return this.http.get(baseUrl + '/restaurantes/' + idOrg);
  }
  public GetRestauranteByIdLang(id: number, lang: number): Observable<any> {
    return this.http.get(baseUrl + '/restaurantes/get/' + id + '/' + lang);
  }

  public crearRestaurante(restaurante: Restaurante): Observable<any> {
    return this.http.post(
      baseUrl + '/createrestaurante/',
      JSON.stringify(restaurante),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }

  public updateRestaurante(restaurante: Restaurante): Observable<any> {
    return this.http.post(
      baseUrl + '/updaterestaurante/',
      JSON.stringify(restaurante),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }

  deleteRestaurantes(data?: any): Observable<any> {
    console.log(JSON.stringify(data));
    var formData: any = new FormData();
    formData.append('codigos', data.codigos);
    console.log(JSON.stringify(formData));
    return this.http.post<JSON>(baseUrl + '/eliminarrestaurantes', formData, {
      withCredentials: true,
    });
  }
  //Comidas Tipos
  public GetComidasFromRestaurante(idRestaurante: string): Observable<any> {
    return this.http.get(baseUrl + '/comida/' + idRestaurante);
  }

  public getComidaByIdLang(idcomida: number, lang: number): Observable<any> {
    return this.http.get(baseUrl + '/comida/get/' + idcomida + '/' + lang);
  }

  public crearComidaTipo(comida: ComidaTipo): Observable<any> {
    comida.horaInicio = new Date(
      this.dateToYYYYMMDDtHHmmSSz(comida.horaInicio)
    );
    comida.horaFin = new Date(this.dateToYYYYMMDDtHHmmSSz(comida.horaFin));
    return this.http.post(baseUrl + '/createcomida/', JSON.stringify(comida), {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        charset: 'utf-8',
      }),
      withCredentials: true,
    });
  }

  public updateComidaTipo(comida: ComidaTipo): Observable<any> {
    comida.horaInicio = new Date(
      this.dateToYYYYMMDDtHHmmSSz(comida.horaInicio)
    );
    comida.horaFin = new Date(this.dateToYYYYMMDDtHHmmSSz(comida.horaFin));
    return this.http.post(baseUrl + '/updatecomida/', JSON.stringify(comida), {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        charset: 'utf-8',
      }),
      withCredentials: true,
    });
  }

  deleteComidasTipos(data?: any): Observable<any> {
    console.log(JSON.stringify(data));
    var formData: any = new FormData();
    formData.append('codigos', data.codigos);
    console.log(JSON.stringify(formData));
    return this.http.post<JSON>(baseUrl + '/eliminar', formData, {
      withCredentials: true,
    });
  }

  //COMEDORES
  public getComedores(idRestaurante: number): Observable<any> {
    return this.http.get(baseUrl + '/comedores/' + idRestaurante);
  }

  public getComedoresByIdLang(id: number, lang: number): Observable<any> {
    return this.http.get(baseUrl + '/comedores/get/' + id + '/' + lang);
  }

  public createComedor(comedor: Comedor): Observable<any> {
    return this.http.post(
      baseUrl + '/createcomedor/',
      JSON.stringify(comedor),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }

  public updateComedor(comedor: Comedor): Observable<any> {
    return this.http.post(
      baseUrl + '/updatecomedor/',
      JSON.stringify(comedor),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );
  }

  deleteComedores(data?: any): Observable<any> {
    console.log(JSON.stringify(data));
    var formData: any = new FormData();
    formData.append('codigos', data.codigos);
    console.log(JSON.stringify(formData));
    return this.http.post<JSON>(baseUrl + '/eliminarcomedores', formData, {
      withCredentials: true,
    });
  }
  //MESAS
  public getMesas(idComedor: number, isMesa: boolean): Observable<any> {
    return this.http.get(baseUrl + '/mesas/' + idComedor + '/' + isMesa);
  }

  public createMesa(mesa: Mesa): Observable<any> {
    return this.http.post(baseUrl + '/createmesa/', JSON.stringify(mesa), {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        charset: 'utf-8',
      }),
      withCredentials: true,
    });
  }

  public updateMesa(mesa: Mesa): Observable<any> {
    return this.http.post(baseUrl + '/updatemesa/', JSON.stringify(mesa), {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        charset: 'utf-8',
      }),
      withCredentials: true,
    });
  }

  deleteMesas(data?: any): Observable<any> {
    console.log(JSON.stringify(data));
    var formData: any = new FormData();
    formData.append('codigos', data.codigos);
    console.log(JSON.stringify(formData));
    return this.http.post<JSON>(baseUrl + '/eliminarmesas', formData, {
      withCredentials: true,
    });
  }
  //MESAS COMEDOR
  public getMesasComedor(idComedor: number): Observable<any> {
    return this.http.get(baseUrl + '/comedor/mesas/' + idComedor);
  }

  //RESERVAS
  public getReservasFromComedor(peticion: ReservaPeticion): Observable<any> {
    return this.http.post(baseUrl + '/getreservas/', JSON.stringify(peticion), {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        charset: 'utf-8',
      }),
      withCredentials: true,
    });
  }

  //GLOBALS
  public getEntesAdministrados(): Observable<any> {
    return this.http.get(
      environment.apiUrl + '/organizacion/getentesuser/' + '1'
    );
  }

  //Helpers
  dateToYYYYMMDDtHHmmSSz(fecha: Date) {
    //2020-10-25T23:00:00Z
    var año = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate(); //getDay da el dia de la semana!
    var hora = fecha.getHours();
    var minutos = fecha.getMinutes();
    var segundos = fecha.getSeconds();
    return (
      año +
      '-' +
      this.addZero(mes) +
      '-' +
      this.addZero(dia) +
      'T' +
      this.addZero(hora) +
      ':' +
      this.addZero(minutos) +
      ':' +
      this.addZero(segundos) +
      'Z'
    );
  }

  addZero(num) {
    if (num < 10) return '0' + num;
    else return num;
  }
}
