<div class="row">
    <div class="col-xl-4 col-md-6">
      <div class="card">
        <!--GESTION AGENTE-->
        <div class="card-header">
          <h3>{{ "recintos.lbl_crear" | translate }}</h3>
        </div>
        <div class="card-body">
          <div class="form-group">
            <!--Lista idiomas-->
  
            <kendo-label text="{{ 'publicaciones.idiomas' | translate }}">
            </kendo-label>
  
            <div class="caja">
              <kendo-dropdownlist
                #dropdownI
                [data]="listaIdiomas"
                textField="nombre"
                valueField="codigo"
                [(ngModel)]="selectedIdioma"
              ></kendo-dropdownlist>
            </div>
          </div>
  
          <div class="form-group">
            <label>{{ "recintos.lbl_nombre_recinto" | translate }}</label>
            <div class="caja">
              <!--Titulo-->
              <kendo-textbox
                placeholder="{{ 'recintos.ph_nombre' | translate }}"
                [clearButton]="true"
                [(ngModel)]="nombreRecinto"
              >
                <ng-template kendoTextBoxPrefixTemplate> </ng-template>
              </kendo-textbox>
            </div>
          </div>

          <!--Comensales-->
          <div class="form-group">
            <kendo-label text="{{ 'restaurante.numcomensales' | translate }}">
              <kendo-numerictextbox
                format="n0"
                [min]="0"
                [step]="1"
                [(ngModel)]="numComensales"
              ></kendo-numerictextbox>
            </kendo-label>
          </div>

          <!-- isMesa-->
          <div class="form-group">
            <kendo-label
              class="k-checkbox-label"
              text="{{ 'restaurante.ismesa' | translate }}"
            ></kendo-label>
            <div class="caja">
              <kendo-switch
                [(ngModel)]="isMesa"
                onLabel="{{ 'switch.activo' | translate }}"
                offLabel="{{ 'switch.inactivo' | translate }}"
              ></kendo-switch>
            </div>
          </div>
  
          <!--Imagen Publicacion-->
          <label class="image-upload-container btn btn-bwm">
            <span>Select Image</span>
            <input
              #imageInput
              type="file"
              accept="image/*"
              (change)="imagePreview($event)"
            />
          </label>
  
          <div *ngIf="filePath && filePath !== ''">
            <img [src]="filePath" style="height: 200px" />
          </div>
  
          <!-- Traducciones -->
          <div class="form-group">
            <button
              type="button"
              class="btn btn-primary btn-sm mr-1"
              (click)="btnAddTraduccion()"
            >
              {{ "publicaciones.anadir" | translate }}
            </button>
            <div class="listado-traducciones">
              <div class="traduccion" *ngFor="let idioma of listaTraducciones">
                {{ idioma.nombre }}
  
                <button
                  type="button"
                  class="btn btn-info btn-sm mr-1"
                  (click)="btnDeleteTraduccion(idioma)"
                >
                  X
                </button>
              </div>
            </div>
            <!--Boton Publicar-->
            <button
              type="button"
              class="btn btn-success btn-sm mr-1"
              (click)="btnCrearRecinto()"
            >
              {{ "recintos.btn_crear" | translate }}
            </button>
            <button
              type="button"
              class="btn btn-danger btn-sm mr-1"
              (click)="btnCancelar()"
            >
              {{ "botones.cancelar" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  