import { Component, OnInit } from '@angular/core';
import { Sitio } from '@app/_models';
import { Router, NavigationEnd } from "@angular/router"

import { RecorridosService } from '@app/_services/recorridos.service';

import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FarmaciasService } from '@app/_services/farmacias.service';
import { AlertService, MenuService } from '@app/_services';
import { Municipio } from "@app/_models/farmacias";

@Component({
  selector: 'app-farmacias',
  templateUrl: './farmacias.component.html',
})
export class FarmaciasComponent implements OnInit {

  listaMunicipios:Municipio[]=[];
  private translate: TranslateService;
  lang;


  constructor(private farmaciaService: FarmaciasService,
    translate: TranslateService,
    private menuService: MenuService, public router: Router,
    private alertService: AlertService,
    private translateService: TranslateService,
    private modalService: NgbModal) {
      this.translate = translate;
      this.menuService.titulo = this.translate.instant('farmacias.farmacias').toUpperCase();
     }

  ngOnInit(): void {
    this.lang = this.translateService.getDefaultLang();

    this.farmaciaService.getMunicipios().subscribe((result)=>{
      console.log(result);
      this.listaMunicipios = result.data;
      this.listaMunicipios.forEach((elem)=>{
        console.log(elem.checked);
        elem.checked=false;
      });
    });
  }

  btnGuardar(){
    this.listaMunicipios = this.listaMunicipios.filter((elem)=> elem.checked==true);
    console.log(this.listaMunicipios);
    if(this.listaMunicipios.length>0){
      this.farmaciaService.actualizarMunicipios(this.listaMunicipios).subscribe((result)=>{
        console.log(result);
        if(!result.error){
          this.alertService.success(
            this.translateService.instant('farmacias.guardado')
          );
        }
        else{
          this.alertService.error(
            this.translateService.instant('farmacias.error')
          );
        }
      });
    }
    else{
      this.alertService.info(
        this.translateService.instant('farmacias.seleccionamunicipio')
      );
    }
  }
  btnCancelar(){

  }


}
