import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizacionesService } from '@app/_services/organizaciones.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService } from '@app/_services/menu.service';
import { PublicacionesService } from '@app/_services/publicaciones.service';
import { IdiomasService } from '@app/_services/idiomas.service';
import { Ente } from '@app/_models/ente';
import { Categoria } from '@app/_models/categoria';
import { Idioma } from '@app/_models/idioma';
import { Noticia, NoticiaTraduccion } from '@app/_models/noticia';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { x } from '@progress/kendo-svg-icons';
import { AlertService, DepartamentosService } from '@app/_services';
import { Recinto, RecintoTrad } from '@app/_models/recinto';
import { RecintosService } from '@app/_services/recintos.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { RestauranteService } from '@app/_services/restaurante.service';
import { ComidaTipo, ComidaTipoTrad, Restaurante, RestauranteTrad } from '@app/_models/restaurante';

@Component({
  selector: 'app-comidastipo-editar',
  templateUrl: './comidastipo-editar.component.html',
})
export class ComidastipoEditarComponent implements OnInit {
  //Listas
  listaIdiomas: Idioma[] = [];
  listaIdiomasTotales: Idioma[] = [];

  //Data
  nombreRecinto: string = '';
  imagenB64: string = '';
  selectedIdioma: Idioma;
  recintoActivo: boolean = true;
  myComida: ComidaTipo = new ComidaTipo();

  lang;
  private translate: TranslateService;
  idOrg: string;
  idRestaurante: number;
  idComida:number;
  fechaIni: Date=new Date();
  fechaFin: Date=new Date();
  rangoHorasReserva:number=1;

  constructor(
    private restauranteService: RestauranteService,
    private idiomaService: IdiomasService,
    private organizacionService: OrganizacionesService,
    private menuService: MenuService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService
  ) {
    this.translate = translate;
    this.menuService.titulo = this.translate.instant('restaurante.cabecera');
  }
  
  ngOnInit(): void {
    this.lang = this.translateService.getDefaultLang();
    this.idComida = this.route.snapshot.params['idcomida'];
    this.idRestaurante = this.route.snapshot.params['idrestaurante'];
    this.idOrg = this.route.snapshot.params['idorg'];
    //Load Langs
    this.idiomaService.GetAll().subscribe((result) => {
      this.listaIdiomasTotales = result.data;
      console.log('Cargados idiomas = ' + this.listaIdiomasTotales.length);

    });

    this.idiomaService.getIdiomasFromEnte(this.idOrg).subscribe((result) => {
      this.listaIdiomas = result.data;
      if (this.listaIdiomas.length > 0){
        this.selectedIdioma = this.listaIdiomas[0];
        this.restauranteService
        .getComidaByIdLang(this.idComida, this.selectedIdioma.codigo)
        .subscribe((result) => {
          console.log(result);
          this.myComida = result.data[0];
          this.nombreRecinto = result.data[0].nombre;
          this.recintoActivo = result.data[0].activo;
          this.fechaIni = new Date(result.data[0].horaInicio);
          this.fechaFin = new Date(result.data[0].horaFin);
          this.rangoHorasReserva = Number(this.myComida.horasReserva);
        });
      }
    });
  }

  loadRecinto(value, dropDownControl: DropDownListComponent) {
    //dropDownControl.writeValue("old value");
    console.log('Cargando Recinto Idioma :' + value.codigo);
    this.restauranteService
      .getComidaByIdLang(this.idComida, this.selectedIdioma.codigo)
      .subscribe((result) => {
        console.log(result);
        this.nombreRecinto = result.data[0].nombre;
      });
  }

  btnUpdateComida() {
    console.log('Actualizando Comida');
    this.myComida.activo = this.recintoActivo;
    this.myComida.nombre = this.nombreRecinto;
    this.myComida.horaInicio=this.fechaIni;
    this.myComida.horaFin=this.fechaFin;
    this.myComida.horasReserva = this.rangoHorasReserva.toString();
    this.myComida.idIdioma = this.selectedIdioma.codigo;
    console.log('Evinado restaurante');
    this.restauranteService
      .updateComidaTipo(this.myComida)
      .subscribe((result) => {
        if (!result.error) {
          this.alertService.success(
            this.translateService.instant('restaurante.successu')
          );
         // this.router.navigate(['comidas', {idorg:this.idOrg, idrestaurante:this.idRestaurante }]);
        } else {
          console.log('Error enviando traducciones');
          this.alertService.error(
            this.translateService.instant('restaurante.erroru')
          );
          return;
        }
      });
  }

  btnCancelar() {
    this.router.navigate(['comidas', {idorg:this.idOrg, idrestaurante:this.idRestaurante }]);
  }
}
