<form #templateForm="ngForm" [formGroup]="form" class="form">
  <div class="row">
    <div class="col-lg-3">
      <!-- Colores-->
      <div class="card">
        <div class="card-header">
          <h3>{{ "configuracion-inicial.colores" | translate }}</h3>
        </div>
        <div class="card-body">

          <div class="row">
            <div class="col">
              <div class="form-group">
                <kendo-label text="{{'configuracion-inicial.colorprincipal' | translate}}">
                </kendo-label>
                <div class="caja">
                  <kendo-colorpicker view="gradient"
                                     format="hex"
                                     name="primaryColor"
                                     [(ngModel)]="fv.primaryColor"
                                     formControlName="primaryColor"
                                     required>
                  </kendo-colorpicker>
                </div>
              </div>


            </div>
            <div class="col">
              <div class="form-group">
                <kendo-label text="{{'configuracion-inicial.colortextoprincipal' | translate}}">
                </kendo-label>
                <div class="caja">


                  <kendo-colorpicker view="gradient"
                                     format="hex"
                                     name="primaryTextColor"
                                     [(ngModel)]="fv.primaryTextColor"
                                     formControlName="primaryTextColor"
                                     required>
                  </kendo-colorpicker>
                </div>

              </div>

            </div>

          </div>
          <div class="row">
            <div class="col">
              <div class="form-group">
                <kendo-label text="{{'configuracion-inicial.colorsecundario' | translate}}">
                </kendo-label>
                <div class="caja">

                  <kendo-colorpicker view="gradient"
                                     format="hex"
                                     name="secondaryColor"
                                     [(ngModel)]="fv.secondaryColor"
                                     formControlName="secondaryColor"
                                     required>
                  </kendo-colorpicker>

                </div>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <kendo-label text="{{'configuracion-inicial.colortextosecundario' | translate}}">
                </kendo-label>
                <div class="caja">
                  <kendo-colorpicker view="gradient"
                                     format="hex"
                                     name="secondaryTextColor"
                                     [(ngModel)]="fv.secondaryTextColor"
                                     formControlName="secondaryTextColor"
                                     required>
                  </kendo-colorpicker>

                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
      <!--End Colores-->
    </div>
    <!--Usuarios-->
    <div class="col-lg-2">
      <div class="card">
        <div class="card-header">
          <h3>{{ "configuracion-inicial.usuarios" | translate }}</h3>
        </div>
        <div class="card-body">

          <div class="row">
            <div class="col">
              <div class="form-group">
                <kendo-label text="{{ 'configuracion-inicial.publicar' | translate }}">
                </kendo-label>
                <div class="caja">
                  <kendo-switch [(ngModel)]="fv.activopublicar"
                                [ngModelOptions]="{ standalone: true }"></kendo-switch>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <kendo-label text="{{ 'configuracion-inicial.comentar' | translate }}">
                </kendo-label>
                <div class="caja">
                  <kendo-switch [(ngModel)]="fv.activocomentar"
                                [ngModelOptions]="{ standalone: true }"></kendo-switch>
                </div>
              </div>
            </div>
          </div>
          <div class="row">

            <div class="col">
              <div class="form-group">
                <kendo-label text="{{ 'configuracion-inicial.seguir' | translate }}">
                </kendo-label>
                <div class="caja">
                  <kendo-switch [(ngModel)]="fv.activoseguir"
                                [ngModelOptions]="{ standalone: true }">
                  </kendo-switch>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <kendo-label text="{{ 'configuracion-inicial.mensajes' | translate }}">

                </kendo-label>
                <div class="caja">
                  <kendo-switch [(ngModel)]="fv.activomensajes" [ngModelOptions]="{ standalone: true }"></kendo-switch>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
    <!--Prioridad Idiomas-->
    <div class="col-lg-2">
      <div class="card">
        <div class="card-header">
          <h3>{{ "configuracion-inicial.prioridad" | translate }}</h3>
        </div>
        <div class="card-body">
          <div class="cont-prioridad-idiomas">
            <div class="prioridad-idiomas-col-posicion">

              <label class="prioridad-idiomas-posicion" *ngFor="let item of listaIndexIdiomas; index as i">
                {{i+1}}
              </label>

            </div>

            <kendo-sortable [kendoSortableBinding]="listaIdiomas"
                            [navigable]="true"
                            [animation]="true"
                            emptyText="Sin idiomas"
                            class="column"
                            itemClass="item-prioridad"
                            activeItemClass="item-prioridad active">
            </kendo-sortable>

          </div>


        </div>

      </div>
    </div>

    <div class="col-lg-4">
      <div class="card">
        <div class="card-header">
          <h3>{{ "configuracion-inicial.extras" | translate }}</h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col">
              <div class="form-group">
                <kendo-label text="{{ 'configuracion-inicial.activarPestana' | translate }}">
                </kendo-label>

                <kendo-switch [(ngModel)]="fv.activoPestana"
                              [ngModelOptions]="{ standalone: true }">
                </kendo-switch>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <kendo-label text="{{ 'configuracion-inicial.footer_noticia' | translate }}">
                </kendo-label>

                <kendo-switch [(ngModel)]="fv.activoFooter"
                              [ngModelOptions]="{ standalone: true }">
                </kendo-switch>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <kendo-label text="{{ 'configuracion-inicial.titulodentro' | translate }}">
                </kendo-label>

                <kendo-switch [(ngModel)]="fv.titulodentro"
                              [ngModelOptions]="{ standalone: true }">
                </kendo-switch>

              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <kendo-label text="{{ 'configuracion-inicial.categoriavisible' | translate }}">
                </kendo-label>


                <kendo-switch [(ngModel)]="fv.activoCategoria"
                              [ngModelOptions]="{ standalone: true }">
                </kendo-switch>
              </div>
            </div>

            <div class="col">
              <div class="form-group">
                <kendo-label text="{{ 'configuracion-inicial.smsverification' | translate }}">
                </kendo-label>


                <kendo-switch [(ngModel)]="fv.smsVerification"
                              [ngModelOptions]="{ standalone: true }">
                </kendo-switch>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <!--Sistema de recompensas-->
    <div class="col-lg-4">
      <div class="card">
        <div class="card-header">
          <h3>{{ "configuracion-inicial.recompensas" | translate }}</h3>
        </div>
        <div class="card-body">
          <div class="row">
            
            <div class="col">
              <div class="form-group">
                <kendo-label text="{{ 'configuracion-inicial.activarrecompensas' | translate }}">
                </kendo-label>
                <kendo-switch [(ngModel)]="fv.activoRecompensas"
                              [ngModelOptions]="{ standalone: true }">
                </kendo-switch>
              </div>
            </div>
            <div class="col" *ngIf="fv.activoRecompensas">
              <div class="form-group">
                <kendo-label text="{{ 'configuracion-inicial.nombretoken' | translate }}">
                  <kendo-textbox
                    formControlName="nombreToken"
                    placeholder="{{ 'configuracion-inicial.ph_nombretoken' | translate }}"
                  >
                  </kendo-textbox>
                </kendo-label>

              </div>

              <div class="form-group">
                <kendo-label text="{{ 'configuracion-inicial.recompensarLogin' | translate }}">
                </kendo-label>
                <kendo-switch [(ngModel)]="fv.recompensarLogin"
                              [ngModelOptions]="{ standalone: true }">
                </kendo-switch>
              </div>

              <div class="form-group">
                <kendo-label text="{{ 'configuracion-inicial.recompensarComentar' | translate }}">
                </kendo-label>
                <kendo-switch [(ngModel)]="fv.recompensarComentar"
                              [ngModelOptions]="{ standalone: true }">
                </kendo-switch>
              </div>

              <div class="form-group">
                <kendo-label text="{{ 'configuracion-inicial.recompensarVotar' | translate }}">
                </kendo-label>
                <kendo-switch [(ngModel)]="fv.recompensarVotar"
                              [ngModelOptions]="{ standalone: true }">
                </kendo-switch>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
    <!--End Usuarios-->
  </div>
  <!--TAB-->
  <div class="row">
    <div class="col">
      <kendo-tabstrip>
        <!--ORGANIZACIONES-->
        <kendo-tabstrip-tab [title]="'Organizaciones'" [selected]="true">
          <ng-template kendoTabContent>
            <div class="row">
              <div class="col">
                <div class="card">
                  <div class="card-header">
                    <h3>
                      {{ "configuracion-inicial.informacion" | translate }}
                    </h3>
                  </div>
                  <div class="card-body">
                    <div class="form-group">
                      <div class="row">
                        <div class="col">
                          <kendo-label text="{{
                              'configuracion-inicial.notificaciones' | translate
                            }}">
                          </kendo-label>
                        </div>
                        <div class="col">
                          <kendo-label text="{{ 'configuracion-inicial.faq' | translate }}">
                          </kendo-label>
                        </div>
                        <div class="col">
                          <kendo-label text="{{
                              'configuracion-inicial.gesdocs' | translate
                            }}">
                          </kendo-label>
                        </div>
                        <div class="col">
                          <kendo-label text="{{
                              'configuracion-inicial.contactos' | translate
                            }}">
                          </kendo-label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <kendo-switch [(ngModel)]="fv.activoNotificaciones"
                                        [ngModelOptions]="{ standalone: true }"></kendo-switch>
                        </div>
                        <div class="col">
                          <kendo-switch [(ngModel)]="fv.activoContactos"
                                        [ngModelOptions]="{ standalone: true }"></kendo-switch>
                        </div>
                        <div class="col">
                          <kendo-switch [(ngModel)]="fv.activoFAQ"
                                        [ngModelOptions]="{ standalone: true }">
                          </kendo-switch>
                        </div>
                        <div class="col">
                          <kendo-switch [(ngModel)]="fv.activoGesDoc"
                                        [ngModelOptions]="{ standalone: true }"></kendo-switch>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card">
                  <div class="card-header">
                    <h3>{{ "configuracion-inicial.rrss" | translate }}</h3>
                  </div>
                  <div class="card-body">
                    <div class="form-group">
                      <div class="row">
                        <div class="col">
                          <kendo-label text="{{
                              'configuracion-inicial.face' | translate
                            }}">
                          </kendo-label>
                        </div>
                        <div class="col">
                          <kendo-label text="{{
                              'configuracion-inicial.twitter' | translate
                            }}">
                          </kendo-label>
                        </div>
                        <div class="col">
                          <kendo-label text="{{
                              'configuracion-inicial.flicker' | translate
                            }}">
                          </kendo-label>
                        </div>
                        <div class="col">
                          <kendo-label text="{{
                              'configuracion-inicial.insta' | translate
                            }}">
                          </kendo-label>
                        </div>
                        <div class="col">
                          <kendo-label text="{{
                              'configuracion-inicial.youtube' | translate
                            }}">
                          </kendo-label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <kendo-switch [(ngModel)]="fv.activoFacebook"
                                        [ngModelOptions]="{ standalone: true }"></kendo-switch>
                        </div>
                        <div class="col">
                          <kendo-switch [(ngModel)]="fv.activoTwitter"
                                        [ngModelOptions]="{ standalone: true }"></kendo-switch>
                        </div>
                        <div class="col">
                          <kendo-switch [(ngModel)]="fv.activoFlicker"
                                        [ngModelOptions]="{ standalone: true }">
                          </kendo-switch>
                        </div>
                        <div class="col">
                          <kendo-switch [(ngModel)]="fv.activoInstagram"
                                        [ngModelOptions]="{ standalone: true }"></kendo-switch>
                        </div>
                        <div class="col">
                          <kendo-switch [(ngModel)]="fv.activoYoutube"
                                        [ngModelOptions]="{ standalone: true }"></kendo-switch>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card">
                  <div class="card-header">
                    <h3>
                      {{ "configuracion-inicial.comunicacion" | translate }}
                    </h3>
                  </div>
                  <div class="card-body">
                    <div class="form-group">
                      <div class="row">
                        <div class="col">
                          <kendo-label text="{{
                              'configuracion-inicial.agenda' | translate
                            }}">
                          </kendo-label>
                        </div>
                        <div class="col">
                          <kendo-label text="{{
                              'configuracion-inicial.mensajes' | translate
                            }}">
                          </kendo-label>
                        </div>
                        <div class="col">
                          <kendo-label text="{{
                              'configuracion-inicial.foro' | translate
                            }}">
                          </kendo-label>
                        </div>
                        <div class="col">
                          <kendo-label text="{{
                              'configuracion-inicial.encuestas' | translate
                            }}">
                          </kendo-label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <kendo-switch [(ngModel)]="fv.activoAgenda"
                                        [ngModelOptions]="{ standalone: true }"></kendo-switch>
                        </div>
                        <div class="col">
                          <kendo-switch [(ngModel)]="fv.activoMensajesOrg"
                                        [ngModelOptions]="{ standalone: true }"></kendo-switch>
                        </div>
                        <div class="col">
                          <kendo-switch [(ngModel)]="fv.activoForo"
                                        [ngModelOptions]="{ standalone: true }">
                          </kendo-switch>
                        </div>
                        <div class="col">
                          <kendo-switch [(ngModel)]="fv.activoEncuestas"
                                        [ngModelOptions]="{ standalone: true }"></kendo-switch>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="card">
                <div class="card-header">
                  <h3>{{ "configuracion-inicial.otros" | translate }}</h3>
                </div>
                <div class="card-body">
                  <div class="form-group">
                    <div class="row">
                      <div class="col">
                        <kendo-label text="{{
                            'configuracion-inicial.reservas' | translate
                          }}">
                        </kendo-label>
                      </div>
                      <div class="col">
                        <kendo-label text="{{
                            'configuracion-inicial.agentes' | translate
                          }}">
                        </kendo-label>
                      </div>
                      <div class="col">
                        <kendo-label text="{{
                            'configuracion-inicial.colaboradores' | translate
                          }}">
                        </kendo-label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <kendo-switch [(ngModel)]="fv.activoReservas"
                                      [ngModelOptions]="{ standalone: true }"></kendo-switch>
                      </div>
                      <div class="col">
                        <kendo-switch [(ngModel)]="fv.activoAgentes"
                                      [ngModelOptions]="{ standalone: true }"></kendo-switch>
                      </div>
                      <div class="col">
                        <kendo-switch [(ngModel)]="fv.activoColaboradores"
                                      [ngModelOptions]="{ standalone: true }">
                        </kendo-switch>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="card">
                <div class="card-header">
                  <h3>
                    {{ "configuracion-inicial.permisosusuario" | translate }}
                  </h3>
                </div>
                <div class="card-body">
                  <kendo-grid [kendoGridBinding]="dataOrganizaciones"
                              [sortable]="true"
                              kendoGridSelectBy="id"
                              [navigable]="true"
                              filterable="menu">
                    <kendo-grid-checkbox-column width="5%"
                                                showSelectAll="true"></kendo-grid-checkbox-column>
                    <kendo-grid-column width="20%"
                                       [style]="{ 'text-align': 'left' }"
                                       field="nombre"
                                       title="{{ 'configuracion-inicial.nombre' | translate }}">
                    </kendo-grid-column>
                    <kendo-grid-column width="20%"
                                       [style]="{ 'text-align': 'left' }"
                                       field="activo"
                                       title="{{ 'configuracion-inicial.publicar' | translate }}">
                      <ng-template kendoGridCellTemplate let-dataItem>
                        <input type="checkbox"
                               [checked]="camposValoresPublicarOrg[dataItem.id]"
                               id="publicar{{ dataItem.id }}"
                               [(ngModel)]="camposValoresPublicarOrg[dataItem.id]"
                               [ngModelOptions]="{ standalone: true }" />
                      </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column width="20%"
                                       [style]="{ 'text-align': 'left' }"
                                       field="activo"
                                       title="{{ 'configuracion-inicial.comentar' | translate }}">
                      <ng-template kendoGridCellTemplate let-dataItem>
                        <input type="checkbox"
                               [checked]="camposValoresComentarOrg[dataItem.id]"
                               id="comentar{{ dataItem.id }}"
                               [(ngModel)]="camposValoresComentarOrg[dataItem.id]"
                               [ngModelOptions]="{ standalone: true }" />
                      </ng-template>
                    </kendo-grid-column>
                  </kendo-grid>
                </div>
              </div>
            </div>
          </ng-template>
        </kendo-tabstrip-tab>
        <!--End ORGANIZACIONES-->
        <!--AGENTES-->
        <kendo-tabstrip-tab [title]="'Agentes'">
          <ng-template kendoTabContent>
            <div class="row">
              <div class="col">
                <div class="card">
                  <div class="card-header">
                    <h3>
                      {{ "configuracion-inicial.informacion" | translate }}
                    </h3>
                  </div>
                  <div class="card-body">
                    <div class="form-group">
                      <div class="row">
                        <div class="col">
                          <kendo-label text="{{
                              'configuracion-inicial.notificaciones' | translate
                            }}">
                          </kendo-label>
                        </div>
                        <div class="col">
                          <kendo-label text="{{ 'configuracion-inicial.faq' | translate }}">
                          </kendo-label>
                        </div>
                        <div class="col">
                          <kendo-label text="{{
                              'configuracion-inicial.gesdocs' | translate
                            }}">
                          </kendo-label>
                        </div>
                        <div class="col">
                          <kendo-label text="{{
                              'configuracion-inicial.contactos' | translate
                            }}">
                          </kendo-label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <kendo-switch [(ngModel)]="fv.activoNotificacionesA"
                                        [ngModelOptions]="{ standalone: true }"></kendo-switch>
                        </div>
                        <div class="col">
                          <kendo-switch [(ngModel)]="fv.activoContactosA"
                                        [ngModelOptions]="{ standalone: true }"></kendo-switch>
                        </div>
                        <div class="col">
                          <kendo-switch [(ngModel)]="fv.activoFAQA"
                                        [ngModelOptions]="{ standalone: true }">
                          </kendo-switch>
                        </div>
                        <div class="col">
                          <kendo-switch [(ngModel)]="fv.activoGesDocA"
                                        [ngModelOptions]="{ standalone: true }"></kendo-switch>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card">
                  <div class="card-header">
                    <h3>{{ "configuracion-inicial.rrss" | translate }}</h3>
                  </div>
                  <div class="card-body">
                    <div class="form-group">
                      <div class="row">
                        <div class="col">
                          <kendo-label text="{{
                              'configuracion-inicial.face' | translate
                            }}">
                          </kendo-label>
                        </div>
                        <div class="col">
                          <kendo-label text="{{
                              'configuracion-inicial.twitter' | translate
                            }}">
                          </kendo-label>
                        </div>
                        <div class="col">
                          <kendo-label text="{{
                              'configuracion-inicial.flicker' | translate
                            }}">
                          </kendo-label>
                        </div>
                        <div class="col">
                          <kendo-label text="{{
                              'configuracion-inicial.insta' | translate
                            }}">
                          </kendo-label>
                        </div>
                        <div class="col">
                          <kendo-label text="{{
                              'configuracion-inicial.youtube' | translate
                            }}">
                          </kendo-label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <kendo-switch [(ngModel)]="fv.activoFacebookA"
                                        [ngModelOptions]="{ standalone: true }"></kendo-switch>
                        </div>
                        <div class="col">
                          <kendo-switch [(ngModel)]="fv.activoTwitterA"
                                        [ngModelOptions]="{ standalone: true }"></kendo-switch>
                        </div>
                        <div class="col">
                          <kendo-switch [(ngModel)]="fv.activoFlickerA"
                                        [ngModelOptions]="{ standalone: true }">
                          </kendo-switch>
                        </div>
                        <div class="col">
                          <kendo-switch [(ngModel)]="fv.activoInstagramA"
                                        [ngModelOptions]="{ standalone: true }"></kendo-switch>
                        </div>
                        <div class="col">
                          <kendo-switch [(ngModel)]="fv.activoYoutubeA"
                                        [ngModelOptions]="{ standalone: true }"></kendo-switch>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card">
                  <div class="card-header">
                    <h3>
                      {{ "configuracion-inicial.comunicacion" | translate }}
                    </h3>
                  </div>
                  <div class="card-body">
                    <div class="form-group">
                      <div class="row">
                        <div class="col">
                          <kendo-label text="{{
                              'configuracion-inicial.agenda' | translate
                            }}">
                          </kendo-label>
                        </div>
                        <div class="col">
                          <kendo-label text="{{
                              'configuracion-inicial.mensajes' | translate
                            }}">
                          </kendo-label>
                        </div>
                        <div class="col">
                          <kendo-label text="{{
                              'configuracion-inicial.foro' | translate
                            }}">
                          </kendo-label>
                        </div>
                        <div class="col">
                          <kendo-label text="{{
                              'configuracion-inicial.encuestas' | translate
                            }}">
                          </kendo-label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <kendo-switch [(ngModel)]="fv.activoAgendaA"
                                        [ngModelOptions]="{ standalone: true }"></kendo-switch>
                        </div>
                        <div class="col">
                          <kendo-switch [(ngModel)]="fv.activoMensajesA"
                                        [ngModelOptions]="{ standalone: true }"></kendo-switch>
                        </div>
                        <div class="col">
                          <kendo-switch [(ngModel)]="fv.activoForoA"
                                        [ngModelOptions]="{ standalone: true }">
                          </kendo-switch>
                        </div>
                        <div class="col">
                          <kendo-switch [(ngModel)]="fv.activoEncuestasA"
                                        [ngModelOptions]="{ standalone: true }"></kendo-switch>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="card">
                <div class="card-header">
                  <h3>{{ "configuracion-inicial.otros" | translate }}</h3>
                </div>
                <div class="card-body">
                  <div class="form-group">
                    <div class="row">
                      <div class="col">
                        <kendo-label text="{{
                            'configuracion-inicial.reservas' | translate
                          }}">
                        </kendo-label>
                      </div>
                      <div class="col">
                        <kendo-label text="{{
                            'configuracion-inicial.agentes' | translate
                          }}">
                        </kendo-label>
                      </div>
                      <div class="col">
                        <kendo-label text="{{
                            'configuracion-inicial.colaboradores' | translate
                          }}">
                        </kendo-label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <kendo-switch [(ngModel)]="fv.activoReservasA"
                                      [ngModelOptions]="{ standalone: true }"></kendo-switch>
                      </div>
                      <div class="col">
                        <kendo-switch [(ngModel)]="fv.activoAgentesA"
                                      [ngModelOptions]="{ standalone: true }"></kendo-switch>
                      </div>
                      <div class="col">
                        <kendo-switch [(ngModel)]="fv.activoColaboradoresA"
                                      [ngModelOptions]="{ standalone: true }">
                        </kendo-switch>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="card">
                <div class="card-header">
                  <h3>
                    {{ "configuracion-inicial.permisosusuario" | translate }}
                  </h3>
                </div>
                <div class="card-body">
                  <kendo-grid [kendoGridBinding]="dataAgentes"
                              [sortable]="true"
                              kendoGridSelectBy="id"
                              [navigable]="true"
                              filterable="menu">
                    <kendo-grid-checkbox-column width="5%"
                                                showSelectAll="true"></kendo-grid-checkbox-column>
                    <kendo-grid-column width="20%"
                                       [style]="{ 'text-align': 'left' }"
                                       field="nombre"
                                       title="{{ 'configuracion-inicial.nombre' | translate }}">
                    </kendo-grid-column>
                    <kendo-grid-column width="20%"
                                       [style]="{ 'text-align': 'left' }"
                                       field="activo"
                                       title="{{ 'configuracion-inicial.publicar' | translate }}">
                      <ng-template kendoGridCellTemplate let-dataItem>
                        <input type="checkbox"
                               [checked]="camposValoresPublicarAgentes[dataItem.id]"
                               id="pubblicarA{{ dataItem.id }}"
                               [(ngModel)]="
                            camposValoresPublicarAgentes[dataItem.id]
                          "
                               [ngModelOptions]="{ standalone: true }" />
                      </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column width="20%"
                                       [style]="{ 'text-align': 'left' }"
                                       field="activo"
                                       title="{{ 'configuracion-inicial.comentar' | translate }}">
                      <ng-template kendoGridCellTemplate let-dataItem>
                        <input type="checkbox"
                               [checked]="camposValoresComentarAgentes[dataItem.id]"
                               id="comentarA{{ dataItem.id }}"
                               [(ngModel)]="
                            camposValoresComentarAgentes[dataItem.id]
                          "
                               [ngModelOptions]="{ standalone: true }" />
                      </ng-template>
                    </kendo-grid-column>
                  </kendo-grid>
                </div>
              </div>
            </div>
          </ng-template>
        </kendo-tabstrip-tab>
        <!--End AGENTES-->
        <!--COLABORADORES-->
        <kendo-tabstrip-tab [title]="'Colaboradores'">
          <ng-template kendoTabContent>
            <div class="row">
              <div class="col">
                <div class="card">
                  <div class="card-header">
                    <h3>
                      {{ "configuracion-inicial.informacion" | translate }}
                    </h3>
                  </div>
                  <div class="card-body">
                    <div class="form-group">
                      <div class="row">
                        <div class="col">
                          <kendo-label text="{{
                              'configuracion-inicial.notificaciones' | translate
                            }}">
                          </kendo-label>
                        </div>
                        <div class="col">
                          <kendo-label text="{{ 'configuracion-inicial.faq' | translate }}">
                          </kendo-label>
                        </div>
                        <div class="col">
                          <kendo-label text="{{
                              'configuracion-inicial.gesdocs' | translate
                            }}">
                          </kendo-label>
                        </div>
                        <div class="col">
                          <kendo-label text="{{
                              'configuracion-inicial.contactos' | translate
                            }}">
                          </kendo-label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <kendo-switch [(ngModel)]="fv.activoNotificacionesC"
                                        [ngModelOptions]="{ standalone: true }"></kendo-switch>
                        </div>
                        <div class="col">
                          <kendo-switch [(ngModel)]="fv.activoContactosC"
                                        [ngModelOptions]="{ standalone: true }"></kendo-switch>
                        </div>
                        <div class="col">
                          <kendo-switch [(ngModel)]="fv.activoFAQC"
                                        [ngModelOptions]="{ standalone: true }">
                          </kendo-switch>
                        </div>
                        <div class="col">
                          <kendo-switch [(ngModel)]="fv.activoGesDocC"
                                        [ngModelOptions]="{ standalone: true }"></kendo-switch>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card">
                  <div class="card-header">
                    <h3>{{ "configuracion-inicial.rrss" | translate }}</h3>
                  </div>
                  <div class="card-body">
                    <div class="form-group">
                      <div class="row">
                        <div class="col">
                          <kendo-label text="{{
                              'configuracion-inicial.face' | translate
                            }}">
                          </kendo-label>
                        </div>
                        <div class="col">
                          <kendo-label text="{{
                              'configuracion-inicial.twitter' | translate
                            }}">
                          </kendo-label>
                        </div>
                        <div class="col">
                          <kendo-label text="{{
                              'configuracion-inicial.flicker' | translate
                            }}">
                          </kendo-label>
                        </div>
                        <div class="col">
                          <kendo-label text="{{
                              'configuracion-inicial.insta' | translate
                            }}">
                          </kendo-label>
                        </div>
                        <div class="col">
                          <kendo-label text="{{
                              'configuracion-inicial.youtube' | translate
                            }}">
                          </kendo-label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <kendo-switch [(ngModel)]="fv.activoFacebookC"
                                        [ngModelOptions]="{ standalone: true }"></kendo-switch>
                        </div>
                        <div class="col">
                          <kendo-switch [(ngModel)]="fv.activoTwitterC"
                                        [ngModelOptions]="{ standalone: true }"></kendo-switch>
                        </div>
                        <div class="col">
                          <kendo-switch [(ngModel)]="fv.activoFlickerC"
                                        [ngModelOptions]="{ standalone: true }">
                          </kendo-switch>
                        </div>
                        <div class="col">
                          <kendo-switch [(ngModel)]="fv.activoInstagramC"
                                        [ngModelOptions]="{ standalone: true }"></kendo-switch>
                        </div>
                        <div class="col">
                          <kendo-switch [(ngModel)]="fv.activoYoutubeC"
                                        [ngModelOptions]="{ standalone: true }"></kendo-switch>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card">
                  <div class="card-header">
                    <h3>
                      {{ "configuracion-inicial.comunicacion" | translate }}
                    </h3>
                  </div>
                  <div class="card-body">
                    <div class="form-group">
                      <div class="row">
                        <div class="col">
                          <kendo-label text="{{
                              'configuracion-inicial.agenda' | translate
                            }}">
                          </kendo-label>
                        </div>
                        <div class="col">
                          <kendo-label text="{{
                              'configuracion-inicial.mensajes' | translate
                            }}">
                          </kendo-label>
                        </div>
                        <div class="col">
                          <kendo-label text="{{
                              'configuracion-inicial.foro' | translate
                            }}">
                          </kendo-label>
                        </div>
                        <div class="col">
                          <kendo-label text="{{
                              'configuracion-inicial.encuestas' | translate
                            }}">
                          </kendo-label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <kendo-switch [(ngModel)]="fv.activoAgendaC"
                                        [ngModelOptions]="{ standalone: true }"></kendo-switch>
                        </div>
                        <div class="col">
                          <kendo-switch [(ngModel)]="fv.activoMensajesC"
                                        [ngModelOptions]="{ standalone: true }"></kendo-switch>
                        </div>
                        <div class="col">
                          <kendo-switch [(ngModel)]="fv.activoForoC"
                                        [ngModelOptions]="{ standalone: true }">
                          </kendo-switch>
                        </div>
                        <div class="col">
                          <kendo-switch [(ngModel)]="fv.activoEncuestasC"
                                        [ngModelOptions]="{ standalone: true }"></kendo-switch>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="card">
                <div class="card-header">
                  <h3>{{ "configuracion-inicial.otros" | translate }}</h3>
                </div>
                <div class="card-body">
                  <div class="form-group">
                    <div class="row">
                      <div class="col">
                        <kendo-label text="{{
                            'configuracion-inicial.reservas' | translate
                          }}">
                        </kendo-label>
                      </div>
                      <div class="col">
                        <kendo-label text="{{
                            'configuracion-inicial.agentes' | translate
                          }}">
                        </kendo-label>
                      </div>
                      <div class="col">
                        <kendo-label text="{{
                            'configuracion-inicial.colaboradores' | translate
                          }}">
                        </kendo-label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <kendo-switch [(ngModel)]="fv.activoReservasC"
                                      [ngModelOptions]="{ standalone: true }"></kendo-switch>
                      </div>
                      <div class="col">
                        <kendo-switch [(ngModel)]="fv.activoAgentesC"
                                      [ngModelOptions]="{ standalone: true }"></kendo-switch>
                      </div>
                      <div class="col">
                        <kendo-switch [(ngModel)]="fv.activoColaboradoresC"
                                      [ngModelOptions]="{ standalone: true }">
                        </kendo-switch>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="card">
                <div class="card-header">
                  <h3>
                    {{ "configuracion-inicial.permisosusuario" | translate }}
                  </h3>
                </div>
                <div class="card-body">
                  <kendo-grid [kendoGridBinding]="dataColaboradores"
                              [sortable]="true"
                              kendoGridSelectBy="id"
                              [navigable]="true"
                              filterable="menu">
                    <kendo-grid-checkbox-column width="5%"
                                                showSelectAll="true"></kendo-grid-checkbox-column>
                    <kendo-grid-column width="20%"
                                       [style]="{ 'text-align': 'left' }"
                                       field="nombre"
                                       title="{{ 'configuracion-inicial.nombre' | translate }}">
                    </kendo-grid-column>
                    <kendo-grid-column width="20%"
                                       [style]="{ 'text-align': 'left' }"
                                       field="activo"
                                       title="{{ 'configuracion-inicial.publicar' | translate }}">
                      <ng-template kendoGridCellTemplate let-dataItem>
                        <input type="checkbox"
                               [checked]="camposValoresPublicarColabs[dataItem.id]"
                               id="pubblicarC{{ dataItem.id }}"
                               [(ngModel)]="camposValoresPublicarColabs[dataItem.id]"
                               [ngModelOptions]="{ standalone: true }" />
                      </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column width="20%"
                                       [style]="{ 'text-align': 'left' }"
                                       field="activo"
                                       title="{{ 'configuracion-inicial.comentar' | translate }}">
                      <ng-template kendoGridCellTemplate let-dataItem>
                        <input type="checkbox"
                               [checked]="camposValoresComentarColabs[dataItem.id]"
                               id="comentarC{{ dataItem.id }}"
                               [(ngModel)]="camposValoresComentarColabs[dataItem.id]"
                               [ngModelOptions]="{ standalone: true }" />
                      </ng-template>
                    </kendo-grid-column>
                  </kendo-grid>
                </div>
              </div>
            </div>
          </ng-template>
        </kendo-tabstrip-tab>
      </kendo-tabstrip>
      <!--End COLABORADORES-->
    </div>
  </div>
  <!--End TAB-->
</form>
<div class="form-group">
  <button kendoButton
          [disabled]="loading"
          (click)="onSubmit()"
          class="btn mr-1 btn-success">
    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
    {{ "botones.guardar" | translate }}
  </button>
  <a routerLink="/configuracion-inicial" class="btn mr-1 btn-danger">
    {{ "botones.cancelar" | translate }}
  </a>
</div>

<style>
</style>
