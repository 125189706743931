import { Component, ViewChild,OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Usuario } from '@app/_models';
import { MenuService, UsuariosService } from '@app/_services';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { RestauranteService } from '@app/_services/restaurante.service';

@Component({
  selector: 'app-mesas',
  templateUrl: './mesas.component.html',
})
export class MesasComponent implements OnInit {

  navigationSubscription;
  public mesas: any = []; //Guarda todas las mesas
  private translate: TranslateService;

  idComedor:number;
  idRestaurante:number;
  idOrg:string;
  
  constructor(private restauranteService: RestauranteService, 
      private menuService: MenuService, public router: Router, public route:ActivatedRoute,
      private translateService: TranslateService,
      private usuariosService: UsuariosService, private modalService: NgbModal,
      ) {    
        this.translate = translateService;        
        this.navigationSubscription = this.router.events.subscribe((e: any) => {
          // If it is a NavigationEnd event re-initalise the component
          if (e instanceof NavigationEnd) {
            if (this.router.url == '/mesas') { 
              this.menuService.titulo = this.translateService.instant('mesas');
              this.cargarDatos();
            }
          }
        });
    }
  
  ngOnInit(): void {
    this.idRestaurante = this.route.snapshot.params['idrestaurante'];
     this.idComedor = this.route.snapshot.params['idcomedor'];
     this.idOrg = this.route.snapshot.params['idorg'];
    this.cargarDatos();
  }

  cargarDatos(){
    this.restauranteService.getMesas(this.idRestaurante,true).subscribe((result) => {
      console.log('MESAS');
      console.log(result);
      this.mesas = result.data;
    });
  }

  onClickNuevo(){
    this.router.navigate(['mesas/editar',{idmesa:-1,idorg:this.idOrg,idrestaurante:this.idRestaurante}]);
  }

  imgClick(element){
    console.log(element);
    this.router.navigate(['mesas/editar/',{idmesa:element.codigo,idrestaurante:this.idRestaurante}]);
  }


}




