<div class="row">
    <div class="col-xl-6 col-md-6">
      <div class="card">
        <!--GESTION AGENTE-->
        <div class="card">
            <div class="card-header">
              <h3>{{ "agentes.gestionar" | translate }}</h3>
            </div>
            <div class="card-body">
              <button
                kendoButton
                (click)="navigateGestionComidas()"
                class="btn mr-1 btn-primary btn-sm mr-1"
              >
                {{ "restaurante.comidas" | translate }}
              </button>

              <button
              kendoButton
              (click)="navigateGesComedores()"
              class="btn mr-1 btn-primary btn-sm mr-1"
            >
              {{ "restaurante.gescomedor" | translate }}
            </button>

            <button
            kendoButton
            (click)="navigateGesMesas()"
            class="btn mr-1 btn-primary btn-sm mr-1"
          >
            {{ "restaurante.gesmesas" | translate }}
          </button>
            </div>
          </div>
        <div class="card-header">
          <h3>{{ "recintos.lbl_editar" | translate }}</h3>
        </div>
        <div class="card-body">
          <div class="form-group">
            <!--Lista idiomas-->
            <kendo-label text="{{ 'publicaciones.idiomas' | translate }}">
            </kendo-label>
            <div class="caja">
              <kendo-dropdownlist
                #dropdownI
                [data]="listaIdiomas"
                textField="nombre"
                valueField="codigo"
                [(ngModel)]="selectedIdioma"
                (valueChange)="loadRecinto($event, dropdownI)"
              ></kendo-dropdownlist>
            </div>
          </div>
  
          <div class="form-group">
            <label>{{ "recintos.lbl_nombre_recinto" | translate }}</label>
            <div class="caja">
              <!--Titulo-->
              <kendo-textbox
                placeholder="{{ 'recintos.ph_nombre' | translate }}"
                [clearButton]="true"
                [(ngModel)]="nombreRecinto"
              >
                <ng-template kendoTextBoxPrefixTemplate> </ng-template>
              </kendo-textbox>
            </div>
          </div>
  
          <!--Cuerpo-->
          <div class="form-group">
            <kendo-label
              text="{{ 'recintos.lbl_descripcion_recinto' | translate }}"
            >
              <textarea
                kendoTextArea
                style="resize: none"
                rows="5"
                [(ngModel)]="descripcionRecinto"
                placeholder="{{ 'recintos.ph_descripcion' | translate }}"
              >
              </textarea>
            </kendo-label>
          </div>
  
          <!--Imagen Publicacion-->
          <label class="image-upload-container btn btn-bwm">
            <span>Select Image</span>
            <input
              #imageInput
              type="file"
              accept="image/*"
              (change)="imagePreview($event)"
            />
          </label>
  
          <div *ngIf="filePath && filePath !== ''">
            <img [src]="filePath" style="height: 200px" />
          </div>
  
          <!--CheckBox Activo-->
          <div class="form-group">
            <kendo-label
              class="k-checkbox-label"
              text="{{ 'recintos.recintoactivo' | translate }}"
            ></kendo-label>
            <div class="caja">
              <kendo-switch
                [(ngModel)]="recintoActivo"
                onLabel="{{ 'switch.activo' | translate }}"
                offLabel="{{ 'switch.inactivo' | translate }}"
              ></kendo-switch>
            </div>
          </div>
          <div class="form-group">
            <!--Boton Publicar-->
            <button
              type="button"
              class="btn btn-success btn-sm mr-1"
              (click)="btnActualizarRecinto()"
            >
              {{ "recintos.btn_actualizar" | translate }}
            </button>
            <button
              type="button"
              class="btn btn-danger btn-sm mr-1"
              (click)="btnCancelar()"
            >
              {{ "botones.cancelar" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>


  </div>
  