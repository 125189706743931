import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganizacionesService } from '@app/_services/organizaciones.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MenuService } from '@app/_services/menu.service';
import { PublicacionesService } from '@app/_services/publicaciones.service';
import { IdiomasService } from '@app/_services/idiomas.service';
import { Ente } from '@app/_models/ente';
import { Categoria } from '@app/_models/categoria';
import { Idioma } from '@app/_models/idioma';
import { Noticia, NoticiaTraduccion } from '@app/_models/noticia';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { x } from '@progress/kendo-svg-icons';
import { AlertService, DepartamentosService } from '@app/_services';
import { Recinto, RecintoTrad } from '@app/_models/recinto';
import { RecintosService } from '@app/_services/recintos.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { RestauranteService } from '@app/_services/restaurante.service';
import { ComidaTipo, ComidaTipoTrad, Restaurante, RestauranteTrad } from '@app/_models/restaurante';
@Component({
  selector: 'app-comidastipo-crear',
  templateUrl: './comidastipo-crear.component.html',
})
export class ComidastipoCrearComponent implements OnInit {
  //Listas
  listaIdiomas: Idioma[] = [];
  listaIdiomasTotales: Idioma[] = [];
  listaTraducciones: Idioma[] = [];

  //Traducciones
  listaTraduccionesEnviar: ComidaTipoTrad[] = [];
  //Data
  nombreRecinto: string = '';
  imagenB64: string = '';
  selectedIdioma: Idioma;
  recintoActivo: boolean = true;
  myComida: ComidaTipo = new ComidaTipo();

  lang;
  private translate: TranslateService;
  idOrg: string;
  idRestaurante: number;
  fechaIni: Date=new Date();
  fechaFin: Date=new Date();
  rangoHorasReserva:number=1;

  constructor(
    private restauranteService: RestauranteService,
    private idiomaService: IdiomasService,
    private organizacionService: OrganizacionesService,
    private menuService: MenuService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    translate: TranslateService,
    private translateService: TranslateService
  ) {
    this.translate = translate;
    this.menuService.titulo = this.translate.instant('restaurante.cabecera');
    this.idOrg = this.route.snapshot.params['idorg'];
    this.idRestaurante = this.route.snapshot.params['idrestaurante'];
  }

  ngOnInit(): void {
    this.lang = this.translateService.getDefaultLang();

    //Load Langs
    this.idiomaService.GetAll().subscribe((result) => {
      this.listaIdiomasTotales = result.data;
      console.log('Cargados idiomas = ' + this.listaIdiomasTotales.length);
      this.listaIdiomasTotales.forEach((x) => {
        const trad = new ComidaTipoTrad();
        trad.filled = false;
        trad.idIdioma = x.codigo;
        trad.nombreIdioma = x.nombre;
        this.listaTraduccionesEnviar.push(trad);
      });
    });

    this.idiomaService.getIdiomasFromEnte(this.idOrg).subscribe((result) => {
      this.listaIdiomas = result.data;
      if (this.listaIdiomas.length > 0)
        this.selectedIdioma = this.listaIdiomas[0];
    });
  }

  btnCrearRecinto() {
    console.log('Creando Publicacion');
    //Validación de los datos
    if (this.listaTraducciones.length <= 0) {
      this.alertService.info(
        this.translateService.instant('publicaciones.errortrads')
      );
      return;
    }


    //AutoCompletado de Idiomas
    //seleccionado
    let seleccionado: ComidaTipoTrad = new ComidaTipoTrad();
    let encontrado: boolean = false;
    this.listaTraduccionesEnviar.forEach((trad) => {
      if (trad.filled && !encontrado) {
        seleccionado = trad;
        encontrado = true;
        return;
      }
    });
    //Fill UnFilled
    this.listaTraduccionesEnviar.forEach((trad) => {
      if (!trad.filled) {
        trad.nombre = seleccionado.nombre;
      }
    });

    console.log(this.listaTraduccionesEnviar);
    //Subir Foto  y EnviarNoticia
    this.myComida.idRestaurante = this.idRestaurante.toString();
    this.myComida.activo = this.recintoActivo;
    this.myComida.nombre = this.nombreRecinto;
    this.myComida.horaInicio=this.fechaIni;
    this.myComida.horaFin=this.fechaFin;
    this.myComida.horasReserva = this.rangoHorasReserva.toString();
    this.myComida.listaTraducciones = this.listaTraduccionesEnviar;
    console.log('Evinado restaurante');
    this.restauranteService
      .crearComidaTipo(this.myComida)
      .subscribe((result) => {
        if (!result.error) {
          this.alertService.success(
            this.translateService.instant('restaurante.success')
          );
          this.router.navigate(['comidas', {idorg:this.idOrg, idrestaurante:this.idRestaurante }]);
        } else {
          console.log('Error enviando traducciones');
          this.alertService.error(
            this.translateService.instant('restaurante.error')
          );
          return;
        }
      });
  }

  btnAddTraduccion() {
    console.log('Comprobando datos');
    if (
      this.selectedIdioma == null ||
      this.nombreRecinto.length <= 0 
    ) {
      if (this.listaTraducciones.length <= 0) {
        this.alertService.info(
          this.translateService.instant('publicaciones.validacion')
        );
        return;
      }
    }
    console.log('Datos validados --->');
    if (!this.listaTraducciones.includes(this.selectedIdioma)) {
      this.listaTraducciones.push(this.selectedIdioma);

      this.listaTraduccionesEnviar.map((x) => {
        if (x.idIdioma == this.selectedIdioma.codigo) {
          x.filled = true;
          x.nombre = this.nombreRecinto;
        }
      });
    } else {
      console.log('Idioma ya añadido');
    }

    console.log(this.listaTraduccionesEnviar);
  }

  btnDeleteTraduccion(idioma: Idioma) {
    console.log(idioma);
    this.listaTraducciones = this.listaTraducciones.filter(
      (x) => x.codigo != idioma.codigo
    );

    this.listaTraduccionesEnviar.map((x) =>
      x.idIdioma == idioma.codigo ? (x.filled = false) : console.log()
    );

    console.log(this.listaTraduccionesEnviar);
  }


  btnCancelar() {
    this.router.navigate(['comidas', {idorg:this.idOrg, idrestaurante:this.idRestaurante }]);
  }
}
