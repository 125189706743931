<div class="clearfix mb-2">
  <button
    id="btnnuevo"
    Class="btn btn-primary btn-sm mr-1"
    (click)="onClickNuevo()"
  >
    {{ "nuevo" | translate }}
  </button>
</div>

<div class="card">
  <div class="card-header">
    <h3>
      <label CssClass="">{{ "mesas" | translate }}</label>
    </h3>
    <div class="plegarpanel"></div>
  </div>
  <div class="card-body">
    <div class="clearfix" *ngIf="mesas">
      <ng-container *ngFor="let mesa of mesas">
        <div class="cajaMesasObjetos">
          <div class="cajaMesasObjetos-inner">
            <div class="img-cont" (click)="imgClick(mesa)">
              <img src="{{ mesa.urlFoto }}" />
            </div>
            <div class="label-cont">
              <label>{{ mesa.nombre }}</label>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<router-outlet></router-outlet>
