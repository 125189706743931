<div class="clearfix">
    <div class="col-comedor">
        <div class="card">
            <div class="card-header">
                <h3>
                    <label CssClass="">{{ 'comedor' | translate }}</label>
                </h3>
                <div class="plegarpanel"></div>
            </div>
            <div class="card-body">
                <div id="prueba"></div>
                <div id="padreCanvas" class="mb-3"
                    style="overflow: hidden;width:600px; height:600px;border: 1px solid black;">
                    <div id="canvas" class="canvas" style="width:600px;height:600px;position: relative;">
                        <table id="fondo" class="fondo" style="position:absolute;width:600px; height:600px;">
                            <tr *ngFor="let imagen of imagenes">
                                <td *ngFor="let imagen of imagenes">
                                    <img [src]="imagen" (click)="fondoClick()" *ngIf="!expandido">
                                    <img [src]="imagen" (click)="fondoClick()" *ngIf="expandido" class="fondoCompleto">
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="botonesComedor text-center">
                    <button class="btn btn-primary btn-sm mr-1" (click)="onClickAgregarMesa()">
                        {{ 'agregarMesa' | translate}}
                    </button>
                    <!-- <button class="btn btn-primary btn-sm mr-1" (click)="onClickAgregarObjeto()">
                        {{ 'agregarObjeto' | translate}}
                    </button> -->
                    <!-- <button class="btn btn-primary btn-sm mr-1" (click)="onClickCambiarFondo()">
                        {{ 'cambiarFondo' | translate}}
                    </button> -->
                    <button class="btn btn-primary btn-sm mr-1" (click)="onClickRotar()">
                        {{ 'rotar' | translate}}
                    </button>
                    <button class="btn btn-danger btn-sm mr-1" (click)="onClickEliminar()">
                        {{ 'eliminar' | translate}}
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="col-datos-comedor">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h3>
                            <label CssClass="">{{ 'mesas' | translate }}</label>
                        </h3>
                        <div class="plegarpanel"></div>
                    </div>
                    <div class="card-body"> 
                        <ng-template #template let-anchor>
                            <span>{{ anchor.nativeElement.innerText }}</span>
                        </ng-template>
                        <div kendoTooltip showOn="none" filter=".k-grid td"
                            (mouseover)="showGridTooltip($event)">
                            <kendo-grid [data]="mesas" kendoGridSelectBy="idAuxiliar" filterable="menu"
                                [navigable]="true" [sortable]="true" scrollable="virtual" [rowHeight]="36"
                                [height]="300" [pageSize]="50"
                                [selectable]="{cell:false, checkboxOnly: false, drag: true, enabled: true, mode: 'multiple'}"
                                [resizable]="true" (cellClick)="cellClick($event)" [selectedKeys]="seleccionados">
                                <!-- Necesitamos que sean sortable, y si no se duplican así no lo son -->
                                <kendo-grid-column width="30%" field="numero" title="{{ 'numero' | translate}}" [style]="{'text-align': 'right'}">
                                </kendo-grid-column>
                                <kendo-grid-column width="30%" field="nombre"
                                    title="{{ 'nombre' | translate}}">
                                </kendo-grid-column>
                                <kendo-grid-column width="30%" field="comensales"
                                    title="{{ 'nComensales' | translate}}" [style]="{'text-align': 'right'}">
                                </kendo-grid-column>
                                <kendo-grid-messages filter="{{'filter' | translate}}"
                                    filterAfterOperator="{{'filterAfterOperator' | translate}}"
                                    filterAfterOrEqualOperator="{{'filterAfterOrEqualOperator' | translate}}"
                                    filterAndLogic="{{'filterAndLogic' | translate}}"
                                    filterBeforeOperator="{{'filterBeforeOperator' | translate}}"
                                    filterBeforeOrEqualOperator="{{'filterBeforeOrEqualOperator' | translate}}"
                                    filterBooleanAll="{{'filterBooleanAll' | translate}}"
                                    filterClearButton="{{'filterClearButton' | translate}}"
                                    filterContainsOperator="{{'filterContainsOperator' | translate}}"
                                    filterDateToday="{{'filterDateToday' | translate}}"
                                    filterDateToggle="{{'filterDateToggle' | translate}}"
                                    filterEndsWithOperator="{{'filterEndsWithOperator' | translate}}"
                                    filterEqOperator="{{'filterEqOperator' | translate}}"
                                    filterFilterButton="{{'filterFilterButton' | translate}}"
                                    filterGtOperator="{{'filterGtOperator' | translate}}"
                                    filterGteOperator="{{'filterGteOperator' | translate}}"
                                    filterIsEmptyOperator="{{'filterIsEmptyOperator' | translate}}"
                                    filterIsFalse="{{'filterIsFalse' | translate}}"
                                    filterIsNotEmptyOperator="{{'filterIsNotEmptyOperator' | translate}}"
                                    filterIsNotNullOperator="{{'filterIsNotNullOperator' | translate}}"
                                    filterIsNullOperator="{{'filterIsNullOperator' | translate}}"
                                    filterIsTrue="{{'filterIsTrue' | translate}}"
                                    filterLtOperator="{{'filterLtOperator' | translate}}"
                                    filterLteOperator="{{'filterLteOperator' | translate}}"
                                    filterNotContainsOperator="{{'filterNotContainsOperator' | translate}}"
                                    filterNotEqOperator="{{'filterNotEqOperator' | translate}}"
                                    filterNumericDecrement="{{'filterNumericDecrement' | translate}}"
                                    filterNumericIncrement="{{'filterNumericIncrement' | translate}}"
                                    filterOrLogic="{{'filterOrLogic' | translate}}"
                                    filterStartsWithOperator="{{'filterStartsWithOperator' | translate}}"
                                    loading="{{'loading' | translate}}"
                                    groupPanelEmpty="{{'groupPanelEmpty' | translate}}" lock="{{'lock' | translate}}"
                                    noRecords="{{'noRecords' | translate}}" unlock="{{'unlock' | translate}}">
                                </kendo-grid-messages>
                            </kendo-grid>
                            <div *ngIf="!datosCargados" class="k-i-loading"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="card">
                    <div class="card-header">
                        <h3>
                            <label CssClass="">{{ 'datos' | translate }}</label>
                        </h3>
                        <div class="plegarpanel"></div>
                    </div>
                    <div class="card-body">
                        <div class="form-group">
                            <!--Lista idiomas-->
                  
                            <kendo-label text="{{ 'publicaciones.idiomas' | translate }}">
                            </kendo-label>
                  
                            <div class="caja">
                              <kendo-dropdownlist
                                #dropdownI
                                [data]="listaIdiomas"
                                textField="nombre"
                                valueField="codigo"
                                [(ngModel)]="selectedIdioma"
                              ></kendo-dropdownlist>
                            </div>
                          </div>
                        <div class="row">
                            <div class="col-6">
                                <div class="row">
                                    <div class="col">
                                        <div class="form-group mr-2">
                                            <kendo-label text="{{ 'nombre' | translate}}">
                                                <kendo-textbox [(value)]="nombre"></kendo-textbox>
                                            </kendo-label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                    <!--Traducciones-->
                                    <button
                                    type="button"
                                    class="btn btn-primary btn-sm mr-1"
                                    (click)="btnAddTraduccion()"
                                  >
                                    {{ "publicaciones.anadir" | translate }}
                                  </button>
                                  <div class="listado-traducciones">
                                    <div class="traduccion" *ngFor="let idioma of listaTraducciones">
                                      {{ idioma.nombre }}
                        
                                      <button
                                        type="button"
                                        class="btn btn-info btn-sm mr-1"
                                        (click)="btnDeleteTraduccion(idioma)"
                                      >
                                        X
                                      </button>
                                    </div>
                                  </div>
                                    <!---->
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group mr-2">
                                            <kendo-label text="{{ 'largo' | translate}}">
                                                <kendo-numerictextbox 
                                                    [(value)]="largo" class="form-control" [min]="0"
                                                    [autoCorrect]="true" [decimals]="0" [format]="'n'">
                                                </kendo-numerictextbox>
                                            </kendo-label>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group mr-2">
                                            <kendo-label text="{{ 'ancho' | translate}}">
                                                <kendo-numerictextbox
                                                    [(value)]="ancho" class="form-control" [min]="0"
                                                    [autoCorrect]="true" [decimals]="0" [format]="'n'">
                                                </kendo-numerictextbox>
                                            </kendo-label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group mr-2">
                                            <kendo-label text="{{ 'mesas' | translate}}">
                                                <kendo-numerictextbox [disabled]="true" [value]="mesas.length"
                                                    class="form-control" [min]="0" [autoCorrect]="true" [decimals]="0"
                                                    [format]="'n'">
                                                </kendo-numerictextbox>
                                            </kendo-label>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group mr-2">
                                            <kendo-label text="{{ 'nComensales' | translate}}">
                                                <kendo-numerictextbox [disabled]="true" [value]="comensales"
                                                    class="form-control" [min]="0" [autoCorrect]="true" [decimals]="0"
                                                    [format]="'n'">
                                                </kendo-numerictextbox>
                                            </kendo-label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="form-group">

    <button (click)="onSubmit()" class="btn btn-primary btn-sm mr-1">
        {{ 'botones.guardar' | translate}}
    </button>
    <button class="btn btn-danger btn-sm mr-1" (click)="onClickCancelar($event)">
        {{ 'botones.atras' | translate}}
    </button>
</div>

<!-- POPUPS MESA  -->
<!-- POPUP: Agregar Mesa  -->
<ng-template #popupAgregarMesa let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{'mesas' | translate}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="btnCancelarMesa()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="clearfix mb-2">
            <button class="btn btn-primary btn-sm mr-1" (click)="onClickNuevaMesa()">{{ 'nuevo' | translate }}</button>
            <button class="btn btn-copiar btn-sm mr-1" (click)="onClickEditarMesa()">{{ 'botones.editar' | translate
                }}</button>
            <button class="btn btn-danger btn-sm mr-1" (click)="onClickEliminarMesa()">{{ 'botones.eliminar' | translate
                }}</button>
        </div>
        <div class="clearfix" *ngIf="mesasModal">
            <ng-container *ngFor="let mesa of mesasModal;let i = index;">
              <div class="cajaMesasObjetos">
                <div class="cajaMesasObjetos-inner">
                  <div class="img-cont" (click)="mesaClick(mesa)">
                    <img id="{{'mesa'+i}}" src={{mesa.urlFoto}} style="width: 100px;">
                    </div>
                    <div class="label-cont">
                      <label>{{mesa.nombre}}</label>
                    </div>
                  </div>
                </div>
</ng-container>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="btnCancelarMesa()">{{ 'botones.cancelar' | translate
            }}</button>
        <button type="button" class="btn btn-primary" (click)="btnAceptarMesa()">{{ 'agregarMesa' | translate
            }}</button>
    </div>
</ng-template>

<!-- POPUP: Nueva/Editar Mesa  -->
<ng-template #popupNuevaEditarMesa let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{'mesas' | translate}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="btnCancelarMesa2()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <kendo-label text="{{ 'nombre' | translate}}">
                        <kendo-textbox [(value)]="nombre1MesaModal"
                            [ngClass]="{ 'is-invalid': submittedModalMesas && nombre1MesaModalError }"></kendo-textbox>
                        <div *ngIf="submittedModalMesas && nombre1MesaModalError" class="invalid-feedback">
                            <div *ngIf="nombre1MesaModalError">{{ 'usuarios.nombrerequerido' | translate}}</div>
                        </div>
                    </kendo-label>

                </div>
            </div>
            <div class="col-6">
                <div class="form-group nolabel">
                    <kendo-textbox [(value)]="nombre2MesaModal"
                        [ngClass]="{ 'is-invalid': submittedModalMesas && nombre2MesaModalError }"></kendo-textbox>
                    <div *ngIf="submittedModalMesas && nombre2MesaModalError" class="invalid-feedback">
                        <div *ngIf="nombre2MesaModalError">{{ 'usuarios.nombrerequerido' | translate}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <div class="form-group">
                    <kendo-label text="{{ 'nComensales' | translate}}">
                        <kendo-numerictextbox class="form-control" [(value)]="numComensalesMesasModal" [decimals]="0"
                            [min]="0" [max]="100" [autoCorrect]="true" [format]="'n'"
                            [ngClass]="{ 'is-invalid': submittedModalMesas && errorComensalesModalMesas }">
                        </kendo-numerictextbox>
                        <div *ngIf="submittedModalMesas && errorComensalesModalMesas" class="invalid-feedback">
                            <div *ngIf="errorComensalesModalMesas">{{ 'numComensalesRequerido' | translate}}</div>
                        </div>
                    </kendo-label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <!--IMAGEN VIEJA-->
                <div class="form-group">
                    <kendo-label text="{{ 'foto' | translate}}"></kendo-label>
                    <div>
                        <img [src]="imageToShowModalMesas" style="max-height: 113px">
                    </div>
                    <div class="form-group">
                        <div class="caja">
                            <kendo-fileselect [(value)]="archivoModalMesas" [restrictions]="restrictionsImage"
                                [multiple]="false" (select)="archivoSeleccionadoModalMesas($event)"
                                (remove)="archivoEliminadoModalMesas($event)">
                                <kendo-upload-messages cancel="{{ 'cancelar' | translate }}"
                                    clearSelectedFiles="{{ 'clearSelectedFiles' | translate }}"
                                    dropFilesHere="{{ 'dropFilesHere' | translate }}"
                                    externalDropFilesHere="{{ 'externalDropFilesHere' | translate }}"
                                    fileStatusFailed="{{ 'fileStatusFailed' | translate }}"
                                    fileStatusUploaded="{{ 'fileStatusUploaded' | translate }}"
                                    filesBatchStatus="{{ 'filesBatchStatus' | translate }}"
                                    filesBatchStatusFailed="{{ 'filesBatchStatusFailed' | translate }}"
                                    filesBatchStatusUploaded="{{ 'filesBatchStatusUploaded' | translate }}"
                                    headerStatusPaused="{{ 'headerStatusPaused' | translate }}"
                                    headerStatusUploaded="{{ 'headerStatusUploaded' | translate }}"
                                    headerStatusUploading="{{ 'headerStatusUploading' | translate }}"
                                    invalidFileExtension="{{ 'invalidFileExtension' | translate }}"
                                    invalidMaxFileSize="{{ 'invalidMaxFileSize' | translate }}"
                                    invalidMinFileSize="{{ 'invalidMinFileSize' | translate }}"
                                    pause="{{ 'pause' | translate }}" remove="{{ 'remove' | translate }}"
                                    resume="{{ 'resume' | translate }}" retry="{{ 'retry' | translate }}"
                                    select="{{ 'select' | translate }}"
                                    uploadSelectedFiles="{{ 'uploadSelectedFiles' | translate }}">
                                </kendo-upload-messages>
                            </kendo-fileselect>
                            <div *ngIf="submittedModalMesas && errorImagenModalMesas" class="invalid-feedback-img">
                                <div *ngIf="errorImagenModalMesas">{{ 'imagenRequerida' | translate}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="btnCancelarMesa2()">{{ 'botones.cancelar' | translate
            }}</button>
        <button type="button" class="btn btn-primary" (click)="btnAceptarMesa2()">{{ 'botones.guardar' | translate
            }}</button>
    </div>
</ng-template>

<!-- POPUP: Borrar mesa  -->
<ng-template #popupBorrarMesa let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title"></h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <label>{{'preguntaeliminarpopup' | translate }}</label>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'botones.cancelar' |
            translate
            }}</button>
        <button type="button" class="btn btn-primary" (click)="btnBorrarAceptarMesa()">{{ 'botones.aceptar' | translate
            }}</button>
    </div>
</ng-template>



<!-- POPUPS OBJETOS  -->
<!-- POPUP: Agregar Objetos  -->
<ng-template #popupAgregarObjeto let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{'objetos' | translate}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="btnCancelarObjeto()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="clearfix mb-2">
            <button class="btn btn-primary btn-sm mr-1" (click)="onClickNuevoObjeto()">{{ 'nuevo' | translate
                }}</button>
            <button class="btn btn-copiar btn-sm mr-1" (click)="onClickEditarObjeto()">{{ 'botones.editar' | translate
                }}</button>
            <button class="btn btn-danger btn-sm mr-1" (click)="onClickEliminarObjeto()">{{ 'botones.eliminar' |
                translate
                }}</button>
        </div>
        <div class="clearfix" *ngIf="objetosModal">
            <ng-container *ngFor="let objeto of objetosModal;let i = index;">
              <div class="cajaMesasObjetos">
                <div class="cajaMesasObjetos-inner" >
                  <div class="img-cont" (click)="objetoClick(objeto)">
                    <img id="{{'objeto'+i}}" src={{objeto.urlFoto}} >
                  </div>
                    <div class="label-cont">
                      <label>{{objeto.nombre}}</label>
                    </div>

                  </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="btnCancelarObjeto()">{{ 'botones.cancelar' | translate
            }}</button>
        <button type="button" class="btn btn-primary" (click)="btnAceptarObjeto()">{{ 'agregarObjeto' | translate
            }}</button>
    </div>
</ng-template>

<!-- POPUP: Nuevo/Editar Objeto -->
<ng-template #popupNuevoEditarObjeto let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{'objetos' | translate}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="btnCancelarObjeto2()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <kendo-label text="{{ 'nombre' | translate}}">
                        <kendo-textbox [(value)]="nombre1ObjetoModal"
                            [ngClass]="{ 'is-invalid': submittedModalObjeto && nombre1ObjetoModalError }">
                        </kendo-textbox>
                        <div *ngIf="submittedModalObjeto && nombre1ObjetoModalError" class="invalid-feedback">
                            <div *ngIf="nombre1ObjetoModalError">{{ 'usuarios.nombrerequerido' | translate}}</div>
                        </div>
                    </kendo-label>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group nolabel">
                    <kendo-textbox [(value)]="nombre2ObjetoModal"
                        [ngClass]="{ 'is-invalid': submittedModalObjeto && nombre2ObjetoModalError }"></kendo-textbox>
                    <div *ngIf="submittedModalObjeto && nombre2ObjetoModalError" class="invalid-feedback">
                        <div *ngIf="nombre2ObjetoModalError">{{ 'usuarios.nombrerequerido' | translate}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <!--IMAGEN VIEJA-->
                <div class="form-group">
                    <kendo-label text="{{ 'foto' | translate}}"></kendo-label>
                    <div>
                        <img [src]="imageToShowModalObjeto" style="max-height: 113px">
                    </div>
                    <div class="form-group">
                        <div class="caja">
                            <kendo-fileselect [(value)]="archivoModalObjeto" [restrictions]="restrictionsImage"
                                [multiple]="false" (select)="archivoSeleccionadoModalObjetos($event)"
                                (remove)="archivoEliminadoModalMesas($event)">
                                <kendo-upload-messages cancel="{{ 'cancelar' | translate }}"
                                    clearSelectedFiles="{{ 'clearSelectedFiles' | translate }}"
                                    dropFilesHere="{{ 'dropFilesHere' | translate }}"
                                    externalDropFilesHere="{{ 'externalDropFilesHere' | translate }}"
                                    fileStatusFailed="{{ 'fileStatusFailed' | translate }}"
                                    fileStatusUploaded="{{ 'fileStatusUploaded' | translate }}"
                                    filesBatchStatus="{{ 'filesBatchStatus' | translate }}"
                                    filesBatchStatusFailed="{{ 'filesBatchStatusFailed' | translate }}"
                                    filesBatchStatusUploaded="{{ 'filesBatchStatusUploaded' | translate }}"
                                    headerStatusPaused="{{ 'headerStatusPaused' | translate }}"
                                    headerStatusUploaded="{{ 'headerStatusUploaded' | translate }}"
                                    headerStatusUploading="{{ 'headerStatusUploading' | translate }}"
                                    invalidFileExtension="{{ 'invalidFileExtension' | translate }}"
                                    invalidMaxFileSize="{{ 'invalidMaxFileSize' | translate }}"
                                    invalidMinFileSize="{{ 'invalidMinFileSize' | translate }}"
                                    pause="{{ 'pause' | translate }}" remove="{{ 'remove' | translate }}"
                                    resume="{{ 'resume' | translate }}" retry="{{ 'retry' | translate }}"
                                    select="{{ 'select' | translate }}"
                                    uploadSelectedFiles="{{ 'uploadSelectedFiles' | translate }}">
                                </kendo-upload-messages>
                            </kendo-fileselect>
                            <div *ngIf="submittedModalObjeto && errorImagenModalObjeto" class="invalid-feedback-img">
                                <div *ngIf="errorImagenModalObjeto">{{ 'imagenRequerida' | translate}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="btnCancelarObjeto2()">{{ 'botones.cancelar' | translate
            }}</button>
        <button type="button" class="btn btn-primary" (click)="btnAceptarObjeto2()">{{ 'botones.guardar' | translate
            }}</button>
    </div>
</ng-template>

<!-- POPUP: Borrar objeto  -->
<ng-template #popupBorrarObjeto let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title"></h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <label>{{'preguntaeliminarpopup' | translate }}</label>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'botones.cancelar' |
            translate
            }}</button>
        <button type="button" class="btn btn-primary" (click)="btnBorrarAceptarObjeto()">{{ 'botones.aceptar' |
            translate }}</button>
    </div>
</ng-template>


<!-- POPUPS FONDOS  -->
<!-- POPUP: Agregar Fondos  -->
<ng-template #popupAgregarFondo let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{'fondos' | translate}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="btnCancelarFondo()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="clearfix mb-2">
            <button class="btn btn-primary btn-sm mr-1" (click)="onClickNuevoFondo()">{{ 'nuevo' | translate }}</button>
            <button class="btn btn-copiar btn-sm mr-1" (click)="onClickEditarFondo()">{{ 'botones.editar' |
                translate}}</button>
            <button class="btn btn-danger btn-sm mr-1" (click)="onClickEliminarFondo()">{{ 'botones.eliminar' |
                translate}}</button>
            <div class="form-group mt-2">
                <label class="control-label">{{ 'ver' | translate }}</label>
                <div class="caja">
                    <div class="btn mr-1" [class.btn-success]="verExpandidoExteriorModalFondo"
                        (click)="changeExpandirExterior(true)" [class.btn-outline-success]="!verExpandidoExteriorModalFondo">
                        <input type="radio" name="informesGroup" id="btnEstimado" class="k-radio"
                            [checked]="verExpandidoExteriorModalFondo" kendoRadioButton [disabled]="true">
                        <label class="k-radio-label" for="btnEstimado">{{ 'expandir' | translate }}</label>
                    </div>
                    <div class="btn mr-1" [class.btn-success]="!verExpandidoExteriorModalFondo"
                        (click)="changeExpandirExterior(false)" [class.btn-outline-success]="verExpandidoExteriorModalFondo">
                        <input type="radio" name="informesGroup" id="btnPredictivo" class="k-radio"
                            [checked]="!verExpandidoExteriorModalFondo" kendoRadioButton [disabled]="true">
                        <label class="k-radio-label" for="btnPredictivo">{{ 'mosaico' | translate}}</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="clearfix" *ngIf="fondosModal">
            <ng-container *ngFor="let fondo of fondosModal;let i = index;">
              <div class="cajaMesasObjetos">
                <div class="cajaMesasObjetos-inner" >
                  <div class="img-cont" (click)="fondoClickModal(fondo)">
                    <img id="{{'fondo'+i}}" src={{fondo.imagenBase64}}>
                    </div>
                    <div class="clearfix">
                      <label>{{fondo.nombre}}</label>
                    </div>
                  </div>
                </div>
</ng-container>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="btnCancelarFondo()">{{ 'botones.cancelar' | translate
            }}</button>
        <button type="button" class="btn btn-primary" (click)="btnAceptarFondo()">{{ 'cambiarFondo' | translate
            }}</button>
    </div>
</ng-template>

<!-- POPUP: Nuevo/Editar Fondo -->
<ng-template #popupNuevoEditarFondo let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{'fondos' | translate}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="btnCancelarFondo2()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col">
                <!--IMAGEN VIEJA-->
                <div class="form-group">
                    <kendo-label text="{{ 'foto' | translate}}"></kendo-label>
                    <div>
                        <img [src]="imageToShowModalFondo" style="max-height: 113px">
                    </div>
                    <div class="form-group">
                        <div class="caja">
                            <kendo-fileselect [(value)]="archivoModalFondo" [restrictions]="restrictionsImage"
                                [multiple]="false" (select)="archivoSeleccionadoModalFondos($event)"
                                (remove)="archivoEliminadoModalFondos($event)">
                                <kendo-upload-messages cancel="{{ 'cancelar' | translate }}"
                                    clearSelectedFiles="{{ 'clearSelectedFiles' | translate }}"
                                    dropFilesHere="{{ 'dropFilesHere' | translate }}"
                                    externalDropFilesHere="{{ 'externalDropFilesHere' | translate }}"
                                    fileStatusFailed="{{ 'fileStatusFailed' | translate }}"
                                    fileStatusUploaded="{{ 'fileStatusUploaded' | translate }}"
                                    filesBatchStatus="{{ 'filesBatchStatus' | translate }}"
                                    filesBatchStatusFailed="{{ 'filesBatchStatusFailed' | translate }}"
                                    filesBatchStatusUploaded="{{ 'filesBatchStatusUploaded' | translate }}"
                                    headerStatusPaused="{{ 'headerStatusPaused' | translate }}"
                                    headerStatusUploaded="{{ 'headerStatusUploaded' | translate }}"
                                    headerStatusUploading="{{ 'headerStatusUploading' | translate }}"
                                    invalidFileExtension="{{ 'invalidFileExtension' | translate }}"
                                    invalidMaxFileSize="{{ 'invalidMaxFileSize' | translate }}"
                                    invalidMinFileSize="{{ 'invalidMinFileSize' | translate }}"
                                    pause="{{ 'pause' | translate }}" remove="{{ 'remove' | translate }}"
                                    resume="{{ 'resume' | translate }}" retry="{{ 'retry' | translate }}"
                                    select="{{ 'select' | translate }}"
                                    uploadSelectedFiles="{{ 'uploadSelectedFiles' | translate }}">
                                </kendo-upload-messages>
                            </kendo-fileselect>
                            <div *ngIf="submittedModalFondo && errorImagenModalFondo" class="invalid-feedback-img">
                                <div *ngIf="errorImagenModalFondo">{{ 'imagenRequerida' | translate}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group">
                    <label class="control-label">{{ 'ver' | translate }}</label>
                    <div class="caja">
                        <div class="btn mr-1" [class.btn-success]="verExpandidoModalFondo"
                            (click)="changeExpandirInterior(true)"
                            [class.btn-outline-success]="!verExpandidoModalFondo">
                            <input type="radio" name="informesGroup" id="btnEstimado" class="k-radio"
                                [checked]="verExpandidoModalFondo" kendoRadioButton [disabled]="true">
                            <label class="k-radio-label" for="btnEstimado">{{ 'expandir' | translate }}</label>
                        </div>
                        <div class="btn mr-1" [class.btn-success]="!verExpandidoModalFondo"
                            (click)="changeExpandirInterior(false)"
                            [class.btn-outline-success]="verExpandidoModalFondo">
                            <input type="radio" name="informesGroup" id="btnPredictivo" class="k-radio"
                                [checked]="!verExpandidoModalFondo" kendoRadioButton [disabled]="true">
                            <label class="k-radio-label" for="btnPredictivo">{{ 'mosaico' | translate}}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="btnCancelarFondo2()">{{ 'botones.cancelar' | translate
            }}</button>
        <button type="button" class="btn btn-primary" (click)="btnAceptarFondo2()">{{ 'botones.guardar' | translate
            }}</button>
    </div>
</ng-template>

<!-- POPUP: Borrar fondo  -->
<ng-template #popupBorrarFondo let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title"></h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <label>{{'preguntaeliminarpopup' | translate }}</label>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'botones.cancelar' |
            translate
            }}</button>
        <button type="button" class="btn btn-primary" (click)="btnBorrarAceptarFondo()">{{ 'botones.aceptar' |
            translate }}</button>
    </div>
</ng-template>

