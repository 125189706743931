import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Usuario } from '@app/_models';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { NumericFilterCellComponent } from '@progress/kendo-angular-grid';

const baseUrl = `${environment.apiUrl}/configuracion`;

@Injectable()
export class ConfiguracionService {

  constructor(private http: HttpClient) { }

  public GetConf(): Observable<any> {
    return this.http.get(baseUrl);
  }

  update(data, cambiarcon) {
    //console.log(JSON.stringify(data));
    //console.log(idTipoPerdida_DAT);
    return this.http.post<any>(baseUrl + "/cambiarConf/" + cambiarcon, { 
      // Datos configuración
      nombre: data.nombre, logo: data.logo, email: data.email,
      usuario: data.usuario, contrasena: data.contrasena, smtp: data.smtp, puerto: data.puerto, incidencias: data.incidencias, noticias: data.noticias, agenda: data.agenda,
      encuestas: data.encuestas, foro: data.foro, farmacias: data.farmacias, censo: data.censo, mensajeria: data.mensajeria, reservas: data.reservas, edadforo: data.edadforo, 
      edadencuestasmin: data.edadencuestasmin, avisos: data.avisos, tablon: data.tablon, texto1: data.texto1, texto2: data.texto2, agentes: data.agentes, facebook: data.facebook, 
      twitter: data.twitter, flicker: data.flicker, instagram: data.instagram, youtube: data.youtube, transportes: data.transportes, contactos: data.contactos, mostrarusuarioreserva: data.mostrarusuarioreserva,
      confirmarreserva: data.confirmarreserva, mindiaslaborables: data.mindiaslaborables, apertura: data.apertura, departUnicoIncid: data.departUnicoIncid, sitios: data.sitios, agendaPublica: data.agendaPublica,
      textEULA1: data.textEULA1, textEULA2: data.textEULA2, agentesUnificados: data.agentesUnificados, documentos: data.documentos, menuTurista: data.menuTurista,
      textoTuristaES: data.textoTuristaES, textoTuristaEU: data.textoTuristaEU, grupos: data.grupos,
      // Datos coordenadas
      latitud: data.latitud, longitud: data.longitud, zoomcor: data.zoomcor,
      // Datos rrss
      fblink: data.fblink, twlink: data.twlink, fllink: data.fllink, inslink: data.inslink, ytlink: data.ytlink,
      // Correos
      correosCenso: data.correosCenso, correosReservas: data.correosReservas, media: data.media
      },
       { withCredentials: true });
  }

  updateSin(data, cambiarcon) {
    //console.log(JSON.stringify(data));
    //console.log(idTipoPerdida_DAT);
    return this.http.post<any>(baseUrl + "/cambiarConfSinLogo/" + cambiarcon, { 
      // Datos configuración
      nombre: data.nombre, email: data.email,
      usuario: data.usuario, contrasena: data.contrasena, smtp: data.smtp, puerto: data.puerto, incidencias: data.incidencias, noticias: data.noticias, agenda: data.agenda,
      encuestas: data.encuestas, foro: data.foro, farmacias: data.farmacias, censo: data.censo, mensajeria: data.mensajeria, reservas: data.reservas, edadforo: data.edadforo, 
      edadencuestasmin: data.edadencuestasmin, avisos: data.avisos, tablon: data.tablon, texto1: data.texto1, texto2: data.texto2, agentes: data.agentes, facebook: data.facebook, 
      twitter: data.twitter, flicker: data.flicker, instagram: data.instagram, youtube: data.youtube, transportes: data.transportes, contactos: data.contactos, mostrarusuarioreserva: data.mostrarusuarioreserva,
      confirmarreserva: data.confirmarreserva, mindiaslaborables: data.mindiaslaborables, apertura: data.apertura, departUnicoIncid: data.departUnicoIncid, sitios: data.sitios, agendaPublica: data.agendaPublica,
      textEULA1: data.textEULA1, textEULA2: data.textEULA2, agentesUnificados: data.agentesUnificados, documentos: data.documentos, menuTurista: data.menuTurista,
      textoTuristaES: data.textoTuristaES, textoTuristaEU: data.textoTuristaEU, grupos: data.grupos, 
      // Datos coordenadas
      latitud: data.latitud, longitud: data.longitud, zoomcor: data.zoomcor,
      // Datos rrss
      fblink: data.fblink, twlink: data.twlink, fllink: data.fllink, inslink: data.inslink, ytlink: data.ytlink,
      // Correos
      correosCenso: data.correosCenso, correosReservas: data.correosReservas, media: data.media
      },
       { withCredentials: true });
  }


  init(): Observable<any> {
    return this.http.get(baseUrl + "/initConf");
  }

  public updateConfig(data):Observable<any>{
    console.log('Updating Configuration...:');
    console.log(JSON.stringify(data));
    return this.http.post<any>(
      baseUrl + '/update',
      {
        primaryColor: (data.primaryColor),
        primaryTextColor: (data.primaryTextColor),
        secondaryColor: (data.secondaryColor),
        secondaryTextColor: (data.secondaryTextColor),
        activopublicar: data.activopublicar,
        activocomentar: data.activocomentar,
        activoseguir: data.activoseguir,
        activomensajes: data.activomensajes,
        activoNotificaciones: data.activoNotificaciones,
        activoContactos: data.activoContactos,
        activoFAQ: data.activoFAQ,
        activoGesDoc: data.activoGesDoc,
        activoFacebook: data.activoFacebook,
        activoTwitter: data.activoTwitter,
        activoFlicker: data.activoFlicker,
        activoInstagram: data.activoInstagram,
        activoYoutube: data.activoYoutube,
        agendaEdadMinima: data.agendaEdadMin,
        activoAgenda: data.activoAgenda,
        activoMensajesOrg: data.activoMensajesOrg,
        activoForo: data.activoForo,
        activoEncuestas: data.activoEncuestas,
        activoReservas: data.activoReservas,
        activoAgentes: data.activoAgentes,
        activoColaboradores: data.activoColaboradores,
        activoNotificacionesA: data.activoNotificaciones==false?false:data.activoNotificacionesA,
        activoContactosA: data.activoContactos==false?false:data.activoContactosA,
        activoFAQA: data.activoFAQ==false?false:data.activoFAQA,
        activoGesDocA: data.activoGesDoc==false?false:data.activoGesDocA,
        activoFacebookA: data.activoFacebook==false?false:data.activoFacebookA,
        activoTwitterA: data.activoTwitter==false?false:data.activoTwitterA,
        agentesActivos: data.activoAgentes,
        agentesTiposActivos: data.activoTipos,
        activoFlickerA: data.activoFlicker==false?false:data.activoFlickerA,
        activoInstagramA: data.activoInstagram==false?false:data.activoInstagramA,
        activoYoutubeA: data.activoYoutube==false?false:data.activoYoutubeA,
        activoAgendaA: data.activoAgenda==false?false:data.activoAgendaA,
        activoMensajesA: data.activoMensajesOrg==false?false:data.activoMensajesA,
        activoForoA: data.activoForo==false?false:data.activoForoA,
        activoEncuestasA: data.activoEncuestas==false?false:data.activoEncuestasA,
        activoReservasA: data.activoReservas==false?false:data.activoReservasA,
        activoAgentesA: data.activoAgentes==false?false:data.activoAgentesA,
        activoColaboradoresA: data.activoColaboradores==false?false:data.activoColaboradoresA,
        activoNotificacionesC: data.activoNotificaciones==false?false:data.activoNotificacionesC,
        activoContactosC: data.activoContactos==false?false:data.activoContactosC,
        activoFAQC: data.activoFAQ==false?false:data.activoFAQC,
        activoGesDocC: data.activoGesDoc==false?false:data.activoGesDocC,
        activoFacebookC: data.activoFacebook==false?false:data.activoFacebookC,
        activoTwitterC: data.activoTwitter==false?false:data.activoTwitterC,
        activoFlickerC: data.activoFlicker==false?false:data.activoFlickerC,
        activoInstagramC: data.activoInstagram==false?false:data.activoInstagramC,
        activoYoutubeC: data.activoYoutube==false?false:data.activoYoutubeC,
        activoAgendaC: data.activoAgenda==false?false:data.activoAgendaC,
        activoMensajesC: data.activoMensajesOrg==false?false:data.activoMensajesC,
        activoForoC: data.activoForo==false?false:data.activoForoC,
        activoEncuestasC: data.activoEncuestas==false?false:data.activoEncuestasC,
        activoReservasC: data.activoReservas==false?false:data.activoReservasC,
        activoAgentesC: data.activoAgentes==false?false:data.activoAgentesC,
        activoColaboradoresC: data.activoColaboradores==false?false:data.activoColaboradoresC,

        pestanaMuroActivo: data.activoPestana,
        footerActivo: data.activoFooter,
        titulodentro: data.titulodentro,
        activoCategoria: data.activoCategoria,
        smsVerification: data.smsVerification,

        activoRecompensas: data.activoRecompensas,
        nombreToken:data.nombreToken,
        recompensarLogin: data.recompensarLogin,
        recompensarComentar: data.recompensarComentar,
        recompensarVotar: data.recompensarVotar,
      },
      { withCredentials: true }
    );
  }

  public updatePermsOrgs(listaOrgs):Observable<any>{
    console.log('Updating PermsOrgs...:');

     return this.http.post<any>(
      baseUrl + '/updatepermisosorg',
      JSON.stringify(listaOrgs),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );


  }

  public updatePermsAgentes(listaAgents):Observable<any>{
    console.log('Updating PermsAegnts...:');
    return this.http.post<any>(
      baseUrl + '/updatepermisosagentes',
      JSON.stringify(listaAgents),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );

  }

  public updatePermsColabs(listaAgents):Observable<any>{
    console.log('Updating PermsColabs...:');
    return this.http.post<any>(
      baseUrl + '/updatepermisoscolabs',
      JSON.stringify(listaAgents),
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
          charset: 'utf-8',
        }),
        withCredentials: true,
      }
    );

  }

  public GetConfig(): Observable<any> {
    return this.http.get(baseUrl+'/getconfig');
  }
  public PRUEBAPI(): Observable<any> {
  return this.http.post('https://desa1.sare.gipuzkoa.net/WAS/AYTO/URJUdalRecursosWSExtWEB/estaEmpadronado',
    {
      idioma:'B',
      dni:'72558254Q',
      dniPeticionario:'12345678UZ',
      fechanac:'19951228',
      nombrePeticionario:'Nombre apellido',
      codprocedimiento:'UDWEBS02'
    },
    {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        charset: 'utf-8',
        USERNAME:'USUWBS17',
        PASSWORD:'DESA',
        IP:'83.213.115.108'
      }),
      withCredentials: true,
    }
  );
  }
}
